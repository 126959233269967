import MetalGold from "../../images/metal-gold-card.png";
import MetalSilver from "../../images/metal-silver-card.png";
import MetalBlack from "../../images/metal_black.png";
import PlasticBlack from "../../images/plastic_black.png";

export const cardImages = {
  metal_silver: MetalSilver,
  metal_gold: MetalGold,
  metal_black: MetalBlack,
  plastic: PlasticBlack,
};
export const cardRequestParams = (cardType, values) => ({
  card_type: cardType,
  salutation: values.salutation,
  gender: values.gender,
  occupation: values.occupation,
  first_name: values.first_name,
  middle_name: values.middle_name,
  last_name: values.last_name,
  phone_code: values.phone_code,
  phone_number: values.phone_number,
  name_on_card: values.name_on_card,
  date_of_birth: values.date_of_birth,
  place_of_birth: values.place_of_birth,
  passport_number: values.passport_number,
  passport_issued_date: values.passport_issued_date,
  passport_expiry_date: values.passport_expiry_date,
  address: values.address,
  city: values.city,
  state: values.state,
  country: values.country,
  postal_code: values.postal_code,
  passport_bio_page: values.passport_bio_page || null,
  passport_with_selfie: values.passport_with_selfie || null,
  digital_signature: values.digital_signature || null,
});

const personalAttributes = [
  "salutation",
  "first_name",
  "middle_name",
  "last_name",
  "phone_code",
  "phone_number",
  "gender",
  "occupation",
  "name_on_card",
];

const identificationAttributes = [
  "date_of_birth",
  "place_of_birth",
  "passport_number",
  "passport_issue_date",
  "passport_expiry_date",
  "passport_bio_page",
  "passport_with_selfie",
  "digital_signature",
];

const mailingAttributes = [
  "address",
  "state",
  "city",
  "country",
  "postal_code",
];

export const formattedApiErrors = (errors) => {
  const errorKeys = [
    ...personalAttributes,
    ...identificationAttributes,
    ...mailingAttributes,
  ];

  const apiErrors = {};

  errorKeys.forEach((key) => {
    apiErrors[`${key}_error`] = errors[key];
  });

  return apiErrors;
};

export const stepFromError = (errors) => {
  const attributes = errors.map((err) => err.attribute);

  if (attributes.some((attr) => personalAttributes.includes(attr))) {
    return "personal";
  } else if (
    attributes.some((attr) => identificationAttributes.includes(attr))
  ) {
    return "identification";
  }

  return "mailing";
};
