import React from "react";
// import { Spinner } from "react-bootstrap";
import Head from "./Head";
import Body from "./Body";

const Table = ({ transfers, loading }) => (
  <div className="px-md-3">
    <div
      className="card mt-3"
      style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", overflowX: "scroll", overflowY: "hidden" }}
    >
      <table className="table table-responsive w-100 d-block d-md-table bg-transparent">
        <Head />
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            Loading...
          </div>
        ) : (
          <Body transfers={transfers} />
        )}
      </table>
    </div>
  </div>
);

export default Table;
