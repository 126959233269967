import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { postRequest } from "../../utils/httpRequest";
import { formErrors } from "../../utils/formErrors";
import { formattedApiErrors, formatCurrency } from "./helper";
import { required } from "../../utils/formValidations";
import "./style.scss";

class Partners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      apiErrors: {},
      submitting: false,
      platforms: ["Facebook", "Twitter", "LinkedIn", "TikTok", "Youtube"],
    };
  }

  handlePlatformChange = (
    platform,
    setFieldValue,
    selectedPlatforms,
    socialMediaData
  ) => {
    const newSelectedPlatforms = selectedPlatforms.includes(platform)
      ? selectedPlatforms.filter((p) => p !== platform)
      : [...selectedPlatforms, platform];
    setFieldValue("selectedPlatforms", newSelectedPlatforms);

    const updatedSocialMediaData = { ...socialMediaData };

    // If the platform is unchecked, remove it from socialMediaData
    if (!newSelectedPlatforms.includes(platform)) {
      delete updatedSocialMediaData[platform];
    }

    // Update the socialMediaData in the form state
    setFieldValue("socialMediaData", updatedSocialMediaData);
  };

  handleSocialMediaInputChange = (
    platform,
    field,
    value,
    setFieldValue,
    socialMediaData
  ) => {
    setFieldValue("socialMediaData", {
      ...socialMediaData,
      [platform]: {
        ...socialMediaData[platform],
        [field]: value,
      },
    });
  };

  submit = (values) => {
    const errors = {};

    if (values.selectedPlatforms.length === 0) {
      errors.social_media =
        "At least one social media platform must be selected.";
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ apiErrors: formattedApiErrors(errors) });
      return;
    }

    this.setState({ submitting: true, apiErrors: {} });

    const {
      hearAboutUsQ,
      businessName,
      accessToApi,
      integrationDescription,
      averageTransactionAmount,
      expectedTransactionalVolume,
      socialMediaData,
    } = values;

    const data = {
      partner: {
        business_name: businessName,
        hear_about_us: hearAboutUsQ,
        access_to_api: "yes",
        integration_description: integrationDescription,
        average_transaction_amount: averageTransactionAmount,
        expected_transactional_volume: expectedTransactionalVolume,
        social_media_data: socialMediaData,
      },
    };

    const crsfToken = $('meta[name="csrf-token"]').attr("content");

    postRequest(`/partners.json`, data, {
      "X-CSRF-Token": crsfToken,
    })
      .then((response) => {
        window.location =
          "/partners?message=Become a partner application submitted successfully";
      })
      .catch((error) => {
        this.setState({ submitting: false });
        if (!error?.data)
          return this.setState({
            apiErrors: {
              unknownError: "An unknown error occurred. Please try again.",
            },
          });
        const errors = formErrors(error.data.errors);

        this.setState({
          apiErrors: formattedApiErrors(errors),
        });
      });
  };

  render() {
    const { step, apiErrors, submitting, platforms } = this.state;
    
    return (
      <div>
        <Formik
          initialValues={{
            hearAboutUsQ: "",
            businessName: "",
            accessToApi: "",
            integrationDescription: "",
            averageTransactionAmount: "",
            expectedTransactionalVolume: "",
            selectedPlatforms: [],
            socialMediaData: {},
          }}
          onSubmit={(values) => this.submit(values)}
        >
          {({ errors, values, handleChange, validateForm, setFieldValue }) => (
            <Form className="form store-form">
              <div className="form-group row required">
                <label
                  htmlFor="hearAboutUsQ"
                  className="form-label font-weight-bold"
                >
                  How did you hear about us?
                </label>
                <Field
                  id="hearAboutUsQ"
                  name="hearAboutUsQ"
                  className={`form-control ${
                    errors.hearAboutUsQ ? "is-invalid" : ""
                  }`}
                  validate={required}
                  onChange={handleChange}
                />
                {errors.hearAboutUsQ && (
                  <div className="error font-0_8">{errors.hearAboutUsQ}</div>
                )}
                {apiErrors?.hearAboutUsError && (
                  <div className="error text-danger">
                    {apiErrors.hearAboutUsError}
                  </div>
                )}
              </div>
              <div className="form-group row">
                <label
                  htmlFor="businessName"
                  className="form-label font-weight-bold"
                >
                  Business Name
                </label>
                <p className="my-1">Legal name of your business</p>
                <Field
                  id="businessName"
                  name="businessName"
                  className={`form-control ${
                    errors.businessName ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                />
                {errors.businessName && (
                  <div className="error">{errors.businessName}</div>
                )}
                {apiErrors?.businessNameError && (
                  <div className="error text-danger">
                    {apiErrors.businessNameError}
                  </div>
                )}
              </div>

              <div className="form-group row required">
                <label
                  htmlFor="expectedTransactionalVolume"
                  className="form-label font-weight-bold font-1_0 mb-2"
                >
                  Expected Transactional Volume
                </label>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="expectedTransactionalVolume"
                      value="over_1_million"
                    />
                    More than 1 million transactions per month
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="expectedTransactionalVolume"
                      value="100000_to_1_million"
                    />
                    100,000 - 1 million transactions per month
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="expectedTransactionalVolume"
                      value="10000_to_100000"
                    />
                    10,000 - 100,000 transactions per month
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="expectedTransactionalVolume"
                      value="1000_to_10000"
                    />
                    1000 - 10,000 transactions/month
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="expectedTransactionalVolume"
                      value="100_to_1000"
                    />
                    100 - 1000 transactions/month
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="expectedTransactionalVolume"
                      value="unknown"
                    />
                    Don't know
                  </label>
                </div>
                {errors.expectedTransactionalVolume && (
                  <div className="error font-0_8">
                    {errors.expectedTransactionalVolume}
                  </div>
                )}
                {apiErrors?.expectedTransactionError && (
                  <div className="error font-0_8 text-danger">
                    {apiErrors.expectedTransactionError}
                  </div>
                )}
              </div>
              <div className="form-group row required">
                <label
                  htmlFor="averageTransactionAmount"
                  className="form-label font-weight-bold mb-1"
                >
                  Average Transaction Amount (USD)
                </label>
                <Field
                  id="averageTransactionAmount"
                  name="averageTransactionAmount"
                  type="text"
                  className={`form-control ${
                    errors.averageTransactionAmount ? "is-invalid" : ""
                  }`}
                  validate={required}
                  value={formatCurrency(values.averageTransactionAmount)}
                  onChange={(e) => {
                    const rawValue = e.target.value.replace(/[$,\.]/g, "");

                    handleChange({
                      target: {
                        name: "averageTransactionAmount",
                        value: rawValue,
                      },
                    });
                    const formattedValue = formatCurrency(rawValue);
                    e.target.value = formattedValue;
                  }}
                />

                {errors.averageTransactionAmount && (
                  <div className="error font-0_8">
                    {errors.averageTransactionAmount}
                  </div>
                )}
                {apiErrors?.averageTransactionAmountError && (
                  <div className="error text-danger">
                    {apiErrors.averageTransactionAmountError}
                  </div>
                )}
              </div>
              <div className="form-group row required">
                <label
                  htmlFor="integrationDescription"
                  className="form-label font-weight-bold mb-1"
                >
                  Describe your Integration with BananaCrystal
                </label>
                <Field
                  as="textarea"
                  id="integrationDescription"
                  name="integrationDescription"
                  validate={required}
                  className={`form-control ${
                    errors.integrationDescription ? "is-invalid" : ""
                  }`}
                  onChange={handleChange}
                />
                {errors.integrationDescription && (
                  <div className="error font-0_8">
                    {errors.integrationDescription}
                  </div>
                )}
                {apiErrors?.integrationDescriptionError && (
                  <div className="error text-danger">
                    {apiErrors.integrationDescriptionError}
                  </div>
                )}
              </div>
              <div className="form-group row display-none">
                <label htmlFor="industry" className="form-label">
                  Will you need access to our API?
                </label>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="accessToApi"
                      value="yes"
                    />
                    Yes
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="accessToApi"
                      value="no"
                    />
                    No
                  </label>
                </div>
                <div class="form-group form-check">
                  <label class="form-check-label">
                    <Field
                      type="radio"
                      className="form-check-input"
                      name="accessToApi"
                      value="unknown"
                    />
                    Don't Know
                  </label>
                </div>
                {errors.accessToApi && (
                  <div className="error">{errors.accessToApi}</div>
                )}
                {apiErrors?.accessToApiError && (
                  <div className="error text-danger">
                    {apiErrors.accessToApiError}
                  </div>
                )}
              </div>

              {apiErrors?.unknownError && (
                <div className="error text-danger font-weight-bold font-0_8">
                  {apiErrors.unknownError}
                </div>
              )}
              <div class="form-group form-check">
                <label
                  htmlFor="social-media"
                  className="social-label font-weight-bold mb-1"
                >
                  Please Select Your Active Social Media Platforms{" "}
                  <span className="text-danger">*</span>
                </label>
                {platforms.map((platform) => (
                  <div key={platform}>
                    <label className="partner_social_margin partner_platform_name">
                      <input
                        id="social-media"
                        type="checkbox"
                        checked={values.selectedPlatforms.includes(platform)}
                        onChange={() =>
                          this.handlePlatformChange(
                            platform,
                            setFieldValue,
                            values.selectedPlatforms,
                            values.socialMediaData
                          )
                        }
                      />
                      {platform}
                    </label>
                  </div>
                ))}
                {apiErrors.socialMediaError && (
                  <div className="error-message text-danger">
                    {apiErrors.socialMediaError}
                  </div>
                )}

                {values.selectedPlatforms.map((platform) => (
                  <div
                    key={platform}
                    style={{ marginTop: "10px" }}
                    className="partner_social_details"
                  >
                    <label className="partner_social_margin font-weight-bold mb-1">
                      {platform}
                    </label>
                    <Field
                      type="number"
                      validate={required}
                      name={`socialMediaData.${platform}.followers`}
                      placeholder={`Number of ${platform} followers`}
                      className={`form-control partner_social_margin ${
                        errors.socialMediaData?.[platform]?.followers
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={(e) =>
                        this.handleSocialMediaInputChange(
                          platform,
                          "followers",
                          e.target.value,
                          setFieldValue,
                          values.socialMediaData
                        )
                      }
                    />
                    <Field
                      type="text"
                      validate={required}
                      name={`socialMediaData.${platform}.handle`}
                      placeholder={`${platform} Social Media Handle`}
                      className={`form-control partner_social_margin ${
                        errors.socialMediaData?.[platform]?.handle
                          ? "is-invalid"
                          : ""
                      }`}
                      onChange={(e) =>
                        this.handleSocialMediaInputChange(
                          platform,
                          "handle",
                          e.target.value,
                          setFieldValue,
                          values.socialMediaData
                        )
                      }
                    />
                  </div>
                ))}
              </div>

              <div className="row action mt-2">
                <button
                  type="submit"
                  className="btn btn-purple w-100"
                  disabled={submitting}
                >
                  {submitting && (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  <b>SUBMIT</b>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default Partners;
