import React from "react";

const TransactionsGrowth = ({ data }) => {
  const regionEntries = Object.entries(data.data.region);

  const generatePieData = (key) => {
    const labels = regionEntries.map(([regionName]) => regionName);
    const values = regionEntries.map(([, regionData]) => regionData[key]);
    return {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#8A2BE2"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#8A2BE2"],
        },
      ],
    };
  };

  // Pie chart data for each metric
  const transactionVolumeData = generatePieData("region_transaction_volume");
  const volumeCountData = generatePieData("volume_count");
  const percentageIncreaseData = generatePieData("percentage_increase");

  return (
    <div
      style={{
        border: "1px solid gray",
        borderRadius: "8px",
        backgroundColor: "#2d3b60",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <h1 style={{ marginBottom: "16px", color: "rgb(255, 245, 224)" }}>
        Trading Volume
      </h1>
      {/* Total Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "16px",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "#f6e1ff",
            padding: "15px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
        >
          <strong>Total:</strong>
          <ul style={{ marginLeft: "16px" }}>
            <li>
              Total Amount:{" "}
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(Number(data.data.total.total_amount).toFixed(2))}
            </li>
            <li>
              Percentage Increase:{" "}
              <strong
                style={{
                  color:
                    data.data.total.percentage_increase <= 0 ? "red" : "green",
                }}
              >
                {data.data.total.percentage_increase.toFixed(2)}%
              </strong>
            </li>
          </ul>
        </div>

        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            padding: "15px",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <strong>Region Percentage Increase:</strong>
          <ul style={{ marginLeft: "16px" }}>
            {Array.from(
              { length: percentageIncreaseData.labels.length },
              (_, index) => (
                <li key={index}>
                  {percentageIncreaseData.labels[index]}:{" "}
                  <strong
                    style={{
                      color:
                        percentageIncreaseData.datasets[0].data[index] <= 0
                          ? "red"
                          : "green",
                    }}
                  >
                    {Number(
                      percentageIncreaseData.datasets[0].data[index]
                    ).toFixed(2)}
                    %
                  </strong>
                </li>
              )
            )}
          </ul>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "16px",
          width: "100%",
        }}
      >
        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgba(255, 255, 255, 0.85)",
            padding: "15px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
        >
          <strong>Region Transaction Volume:</strong>
          <ul style={{ marginLeft: "16px" }}>
            {Array.from(
              { length: transactionVolumeData.labels.length },
              (_, index) => (
                <li key={index}>
                  {transactionVolumeData.labels[index]}:{" "}
                  <strong>
                    {transactionVolumeData.datasets[0].data[index] != null
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          Number(
                            transactionVolumeData.datasets[0].data[index]
                          ).toFixed(2)
                        )
                      : "N/A"}
                  </strong>
                </li>
              )
            )}
          </ul>
        </div>

        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgba(245, 245, 245, 0.85)",
            padding: "15px",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <strong>Region Volume Count:</strong>
          <ul style={{ marginLeft: "16px" }}>
            {Array.from(
              { length: volumeCountData.labels.length },
              (_, index) => (
                <li key={index}>
                  {volumeCountData.labels[index]}:{" "}
                  <strong>
                    {volumeCountData.datasets[0].data[index] != null
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          Number(
                            volumeCountData.datasets[0].data[index]
                          ).toFixed(2)
                        )
                      : "N/A"}
                  </strong>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TransactionsGrowth;
