import React, { useState } from "react";
import { CardType } from "../../Types";
import plasticBlack from "../../../../../../../assets/images/plastic_black.png";
import metalSilver from "../../../../../../../assets/images/metal_silver.png";
import metalGold from "../../../../../../../assets/images/metal_gold.png";
import metalBlack from "../../../../../../../assets/images/metal_black.png";
import CardDetail from "./CardDetail";
import Modal from "./Modal";

interface Props {
  availableBalance: number;
  isQAUser: boolean;
  cardTypes: CardType[];
  onSelectCardType: (e: any, cardType: string) => void;
  updateCouponCode: (couponCode: string) => void;
}

const CardTypes: React.FC<Props> = ({
  availableBalance,
  isQAUser,
  cardTypes,
  onSelectCardType,
  updateCouponCode,
}) => {
  const [modalContent, setModalContent] = useState<string | null>(null);

  const onClickCardType = (e: any, cardType: CardType) => {
    e.preventDefault();

    const isInsufficientBalance = (requiredFee) =>
      requiredFee > availableBalance;

    if (
      cardType.is_coupon_claimable &&
      isInsufficientBalance(Number(cardType.discounted_fee))
    ) {
      alert("You have insufficient balance to apply for this card");
      return;
    }

    if (
      !cardType.is_coupon_claimable &&
      isInsufficientBalance(cardType.application_fee)
    ) {
      alert("You have insufficient balance to apply for this card");
      return;
    }

    window.scrollTo(0, 0);
    onSelectCardType(e, cardType.type);
  };

  const handleTooltipClick = (tooltipText: string) => {
    setModalContent(tooltipText);
  };

  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <>
      <div className="header">
        <div className="nav-steps d-none d-sm-flex pl-3">
          <span>Choose Your Card Type</span>
        </div>
      </div>
      <div className="cards-title-padding pr-3">
        <div className="cards-section">
          {cardTypes.map((cardType, i) => (
            <div
              key={i}
              className="cards-container"
              style={{ backgroundColor: "#f5f4f9" }}
            >
              <CardDetail
                cardType={cardType}
                onClickCardType={onClickCardType}
                handleTooltipClick={handleTooltipClick}
                updateCouponCode={updateCouponCode}
              />
            </div>
          ))}
        </div>
      </div>

      {modalContent && (
        <Modal modalContent={modalContent} closeModal={closeModal} />
      )}
    </>
  );
};

export default CardTypes;
