import moment from "moment";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const personalDetails = (data) => {
  const {
    first_name,
    last_name,
    middle_name,
    salutation,
    occupation,
    phone_code,
    phone_number,
    gender,
    name_on_card,
  } = data;

  return {
    firstName: capitalizeFirstLetter(first_name),
    lastName: capitalizeFirstLetter(last_name),
    middleName: capitalizeFirstLetter(middle_name),
    salutation: capitalizeFirstLetter(salutation),
    occupation: capitalizeFirstLetter(occupation),
    nameOnCard: capitalizeFirstLetter(name_on_card),
    phoneNumber: `(${phone_code}) ${phone_number}`,
    gender: capitalizeFirstLetter(gender),
  };
};

export const identificationDetails = (data) => {
  const {
    place_of_birth,
    passport_number,
    date_of_birth,
    passport_issued_date,
    passport_expiry_date,
    passport_bio_page,
    passport_with_selfie,
    digital_signature,
  } = data;

  let dateOfBirth = moment(date_of_birth);
  let passportIssuedDate = moment(passport_issued_date);
  let passportExpiryDate = moment(passport_expiry_date);

  return {
    placeOfBirth: capitalizeFirstLetter(place_of_birth),
    passportNumber: passport_number,
    dateOfBirth: date_of_birth ? dateOfBirth.format("DD-MM-YYYY") : "",
    passportIssuedDate: passport_issued_date
      ? passportIssuedDate.format("DD-MM-YYYY")
      : "",
    passportExpiryDate: passport_expiry_date
      ? passportExpiryDate.format("DD-MM-YYYY")
      : "",
    passportBioPage: passport_bio_page,
    passportWithSelfie: passport_with_selfie,
    digitalSignature: digital_signature,
  };
};

export const mailingDetails = (data) => {
  const { address, city, state, country, postal_code } = data;

  return {
    address: capitalizeFirstLetter(address),
    city: capitalizeFirstLetter(city),
    state: capitalizeFirstLetter(state),
    country: capitalizeFirstLetter(country),
    postalCode: postal_code,
  };
};
