import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import countryToCurrrency  from "../../../../../../../utils/countryToCurrency.json"
import {
  getRequest,
} from "../../../../../../../utils/httpRequest";

const validateInput = (pattern, value) => {
  return pattern.test(value);
};

const StepOne = ({
  accountType,
  countries,
  currencies,
  bankAccountCurrency,
  bankAccountCountry,
  handleCurrencyChange,
  handleCountryChange,
  handleInputChange,
  handleStepChange,
  handleCloseModal,
  isFetchingBanks,
}) => (
  <form>
    <div>
      <div className="form-group">
        <label>Account type{"  "}</label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          className="custom-control-input"
          type="radio"
          name="accountType"
          id="personal"
          value="personal"
          checked={accountType === "personal"}
          onChange={e => handleInputChange(e, "accountType")}
        />
        <label className="custom-control-label" htmlFor="personal">
          Personal
        </label>
      </div>
      <div className="custom-control custom-radio custom-control-inline">
        <input
          className="custom-control-input"
          type="radio"
          name="accountType"
          id="business"
          value="business"
          checked={accountType === "business"}
          onChange={e => handleInputChange(e, "accountType")}
        />
        <label className="custom-control-label" htmlFor="business">
          Business
        </label>
      </div>
    </div>
    <div className="form-group">
      <label htmlFor="">Bank account country</label>
      <Select
        className="currencies-select-container"
        options={countries}
        value={bankAccountCountry}
        onChange={data => handleCountryChange(data)}
      />
    </div>
    <div className="form-group">
      <label htmlFor="">Currency</label>
      <input value={bankAccountCurrency} type="text" className="currencies-select-container selected_currency_input" />
      {/* <Select
        className="currencies-select-container"
        options={currencies}
        value={selectedUserCurrency}
        onChange={data => handleCurrencyChange(data)}
      /> */}
    </div>
    <div className="createoffermodal-addaccount-btns-cont">
      <button
        type="button"
        className="createoffer-modal-addaccount-cancelbutton"
        data-dismiss="modal"
        onClick={e => handleCloseModal(e)}
      >
        Close
      </button>
      <button
        type="submit"
        disabled={isFetchingBanks}
        className="createoffer-modal-addaccount-button"
        onClick={e => handleStepChange(e, "two")}
      >
        {
          isFetchingBanks ? "Please Wait..." : "Next"
        }
      </button>
    </div>
  </form>
);

const bankOfNigeria = [
  'AB microfinance bank',
  'AG mortgage Bank ',
  'AKU microfinance Bank',
  'ASO savings & Loan',
  'AWACASH microfinance Bank',
  'Abbey mortgage Bank',
  'Access Bank',
  'Access Money',
  "Access Y'ello & Beta",
  'Accion MFB',
  'Addosser  Microfinance Bank',
  'Advans la Fayette',
  'Afemai microfinance bank',
  'Akwa savings & loan limited',
  'Alvana microfinance Bank',
  'Amucha microfinance Bank',
  'Aniocha Microfinance Bank ',
  'AssetMatrix MFB',
  'Avuenegbe microfinance Bank limited',
  'Aztec microfinance Bank',
  'BANC CORP MICROFINANCE BANK',
  'BOSAK MFB',
  'Balera microfinance bank',
  'Bankly microfinance bank ',
  'Boabab microfinance Bank',
  'Beststar MFB',
  'Boctrust microfinance Bank',
  'Borstal microfinance Bank',
  'Branch international financial services',
  'Brent mortgage Bank',
  'Brightway microfinance Bank',
  'Build microfinance Bank',
  'Bunkure microfinance Bank',
  'CARBON',
  'Confidence microfinance Bank',
  'CORESTEP MFB',
  'Cross River microfinance finance LTD',
  'Capstone microfinance Bank',
  'Cashconnect microfinance Bank',
  'Cashbridge microfinance Bank',
  'Cellulant ',
  'Chanelle microfinance Bank',
  'Chikum microfinance Bank',
  'Citizen trust microfinance Bank',
  'Consistent Trust microfinance Bank',
  'Covenant microfinance Bank',
  'Diamond bank',
  'DOT microfinance Bank',
  'Eagle flight MFB',
  'Eartholeum',
  'Eco Bank Nigeria PLC',
  'Ecobank Xpress Account ',
  'Enterprise Bank',
  'Entity Microfinance Bank',
  'eTransact',
  'FCMB Easy Account',
  'FCMB ( First city momentum bank )',
  'FET',
  'FFS MFB',
  'FINCA MFB',
  'FIRSTMONIE WALLET',
  'FSDH merchant bank limited',
  'Fairmoney MFB',
  'Fedeth microfinance Bank',
  'Fidelity Bank',
  'Fidelity Mobile',
  'FinaTrust MFB',
  'Firmus microfinance Bank',
  'First Bank of Nigeria',
  'Flourish MFB',
  'FortisMobile',
  'Fortress microfinance Bank',
  'GTMobile',
  'Gabsyn microfinance Bank',
  'Gateway mortgage Bank',
  'Globus bank ',
  'Glory microfinance Bank',
  'GoMoney',
  'Goldman MFB',
  'Gombe microfinance Bank',
  'Good news microfinance Bank',
  'Guaranty Trust Bank',
  'Hedonmark',
  'Heritage Banking',
  'Hope PSB',
  'ILORA microfinance Bank',
  'ISALE OYO MFB',
  'Ibile microfinance bank limited',
  'Ibolo MFB',
  'Ibom fadama MFB ltd',
  'Ibu-Aje microfinance Bank',
  'Ikire microfinance Bank',
  'Infinity microfinance Bank',
  'Insight microfinance Bank',
  'Intellifin',
  'Iyamoye microfinance Bank',
  'Jaiz bank PLC',
  'Jubilee life mortgage',
  'KC microfinance Bank',
  'Kayi microfinance Bank',
  'Kegow ( chamsmobile )',
  'Keystone Bank',
  'KongoPay Technologies limited',
  'Kredi money microfinance Bank',
  'Kuda MFB',
  'Lapo microfinance Bank',
  'Lavender microfinance Bank',
  'Leadcity microfinance Bank',
  'Letshego microfinance Bank',
  'LifeGate MFB',
  'Links microfinance Bank',
  'LivingTrust mortgage Bank PLC',
  'Loma Bank',
  'Lotus Bank',
  'Lovonus microfinance Bank',
  'MINT - FINEX  MFB',
  'MONIE POINT',
  'Mainland microfinance Bank',
  'Microvis Microfinance Bank',
  'Mkobo MFB',
  'Mkudi',
  'Momo payment service bank',
  'Monarch Microfinance Bank',
  'MoneyBox',
  'MoneyMaster PSB',
  'NDDC staff coop Microfinance Bank Limited',
  'NPF microfinance Bank limited',
  'Neptune microfinance Bank limited',
  'New Dawn Microfinance Bank',
  "Nigeria Int'l Bank(CITIGROUP)",
  'NowNow',
  'Opay',
  'Oche Microfinance Bank Limited',
  'Octopus Microfinance Bank',
  'Odoakpu Microfinance Bank Limited',
  'Okengwe Microfinance Bank',
  'Okuku Microfinance Bank',
  'Optimus Bank',
  'Orisun Microfinance Bank',
  'PAYSTACK - TITAN',
  'Pagan',
  'Palmpay',
  'Parallex Bank',
  'Parkway Bank',
  'PatrickGold Microfinance Bank',
  'Peace microfinance Bank',
  'Pillar Microfinance Bank',
  'Polaris Bank PLC',
  'Preeminent Microfinance Bank',
  'Premium Trust Bank',
  'Providus Bank PLC',
  'PurpleMoney Microfinance Bank',
  'Pyramid Microfinance Bank',
  'Rand Merchant Bank',
  'Rehoboth Microfinance Bank',
  'RenMoney',
  'Revelation Microfinance Bank',
  'Rigo Microfinance Bank Limited',
  'Royal Blue Microfinance Bank',
  'Rubies MFB',
  'SLS Microfinance Bank',
  'SOLID ALLIIANZE MFB',
  'SPECTRUM MICROFINANCE BANK',
  'Safe Haven Microfinance Bank',
  'Signature Bank',
  'SmartCash payment service Bank',
  'Source Microfinance Bank',
  'Sparkle',
  'Stanbic IBTC @ease wallet ',
  'Stanbic IBTC Bank PLC',
  'Standard chartered Bank ',
  'State side Microfinance Bank',
  'Stella MFB',
  'Sterling Bank',
  'Sulspap Microfinance Bank',
  'Suntrust Bank',
  'Support Microfinance Bank Limited',
  'TAJBank',
  'TAJ_PINSPAY',
  'TANADI MFB ( CRUST )',
  'THRIVE MFB',
  'Tagpay',
  'TeasyMobile',
  'Think MFB',
  'Titan Trust Bank',
  'UDA Microfinance Bank',
  'UNN staff Microfinance Bank',
  'Umuchukwu Microfinance Bank',
  'Union Bank of Nigeria',
  'United Bank for Africa',
  'Unity Bank PLC',
  'VFD MFB',
  'VTNetworks',
  'Vale Finance Limited',
  'WAYA Microfinance Bank',
  'Wema Bank PLC',
  'Wetland MFB',
  'Yes Microfinance Bank',
  'ZWALLET',
  'Zenith Bank',
  'Zenith Mobile',
  'Zikora Microfinance Bank',
  '9PSB',
  '9jaPay Microfinance Bank',
  'Other'
];

const StepTwo = ({
  accountType,
  bankAccountCurrency,
  bankAccountCountry,
  bankName,
  accountName,
  customBankDetails,
  accountNumber,
  routingNumber,
  sortCode,
  swiftCode,
  clabe,
  iban,
  countryOfResidence,
  state,
  city,
  zipCode,
  address,
  handleInputChange,
  showIntDetails,
  toggleShowIntDetails,
  handleStepChange,
  isSubmitting,
  onSubmit,
  internationalEndRef,
  handleBankNameChange,
  interacEmail,
  countryBanks
}) => {
  const patterns = {
    name: /^[A-Za-z\s]*$/,
    numbersOnly: /^\d*$/,
    alphanumeric: /^[A-Za-z0-9:\s-]*$/,
    lettersOnly: /^[A-Za-z]*$/,
    email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  };

  const [bankList, setBankList] = useState(bankOfNigeria)

  useEffect(() => {
    if (bankAccountCountry.value !== "NG") {
      let allBanks = countryBanks.length > 0 ?  countryBanks[0].banks : []
      allBanks = [
        ...allBanks,
        "Other"
      ]
      setBankList(allBanks)
    }
  }, [bankAccountCountry])

 


  return (
  <>
    <div className="col-md-12 step-two-container">
      <div className="form-group">
        <label>Account type: {accountType.toUpperCase()}</label>
        <p>
          {bankAccountCountry?.label} paying in{" "}
          <strong>{bankAccountCurrency}</strong>
        </p>
      </div>
      <div className="form-group">
        {(
          <>
            <strong>
              <label htmlFor="">Bank name</label>
              <sup style={{color: "red"}}>*</sup>
            </strong>
            <Select
              className="currencies-select-container"
              options={bankList.map((bank) => ({
                label: bank,
                value: bank,
              }))}
              value={{
                label: bankName,
                value: bankName,
              }}
              onChange={data => {
                handleBankNameChange(data); // Set the value for bankName
                if (data.label === "Other") {
                  document.querySelector("#otherBankInput").style.display = "block";
                } else {
                  document.querySelector("#otherBankInput").style.display = "none";
                }
              }}
            />
          <div id="otherBankInput" style={{display: "none"}}>
            <strong>
              <label htmlFor="">Other Bank name</label>
              <sup style={{color: "red"}}>*</sup>
            </strong>
            <input
            type="text"

            className="form-control"
            placeholder="Enter your bank name"
            value={customBankDetails}
            onChange={e =>{if (!validateInput(patterns["name"], e.target.value)) return;
              handleInputChange(e, "customBankDetails");}}
            />
          </div>          
        </>) 
        }
        {/* {bankAccountCountry?.value !== 'NG' &&
        <div>
          <strong>
            <label htmlFor="">Bank name</label>
            <sup style={{color: "red"}}>*</sup>
          </strong>
          <input
            type="text"
            className="form-control"
            placeholder="Enter your bank name"
            value={bankName}
            onChange={e =>{if (!validateInput(patterns["name"], e.target.value)) return;
              handleInputChange(e, "bankName");}}
          />
        </div>
        } */}
      </div>
      <div className="form-group">
        <strong><label htmlFor="">Account Holder's Name</label>
        <sup style={{color: "red"}}>*</sup></strong>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your account name"
          value={accountName}
          onChange={e => {if (!validateInput(patterns["name"], e.target.value)) return;
            handleInputChange(e, "accountName");}}
        />
      </div>
      <div className="form-group">
        <strong><label htmlFor="">Account Number</label></strong>
        <input
          type="type"
          className="form-control"
          placeholder="Enter your account number"
          value={accountNumber}
          onChange={e => {if (!validateInput(patterns["numbersOnly"], e.target.value)) return;
            handleInputChange(e, "accountNumber");}}
        />
      </div>

      {bankAccountCountry?.value === 'US' && (<div className="form-group">
        <strong><label htmlFor="">Routing Number</label></strong>
        <input
          type="type"
          className="form-control"
          placeholder="Enter your routing number"
          value={routingNumber}
          onChange={e => {if (!validateInput(patterns["numbersOnly"], e.target.value)) return;
            handleInputChange(e, "routingNumber");}}
        />
      </div>
      )}

      {bankAccountCountry?.value === 'MX' && (<div className="form-group">
        <strong><label htmlFor="">Clabe</label></strong>
        <input
          type="text"
          className="form-control"
          placeholder="Enter your clabe"
          value={clabe}
          onChange={e => {if (!validateInput(patterns["alphanumeric"], e.target.value)) return;
            handleInputChange(e, "clabe");}}
        />
      </div>
      )}

      {bankAccountCountry?.value === "GB" && (
        <>
          <div className="form-group">
            <label htmlFor="">Sort code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your sort code"
              value={sortCode}
              onChange={e => {if (!validateInput(patterns["numbersOnly"], e.target.value)) return;
                handleInputChange(e, "sortCode");}}
            />
            <small>Your sort code is required for most bank transfers.</small>
          </div>
        </>
      )}

      {!["NG", "US", "CA"].includes(bankAccountCountry?.value) && (
        <>
          <div className="form-group">
          <strong><label htmlFor="">SWIFT / BIC code:</label></strong>
           <input
             type="text"
             className="form-control"
             placeholder="Enter your SWIFT / BIC code"
             value={swiftCode}
             onChange={e => {if (!validateInput(patterns["alphanumeric"], e.target.value)) return;
               handleInputChange(e, "swiftCode");}}
           />
          </div>
          <div className="form-group">
            <strong><label htmlFor="">Routing Number/Transit No/Branch Code:</label></strong>
            <input
              type="number"
              className="form-control"
              placeholder="Enter number"
              value={routingNumber}
              onChange={e => {if (!validateInput(patterns["numbersOnly"], e.target.value)) return;
                handleInputChange(e, "routingNumber");}}
            />
          </div>
        </>
      )}

      {bankAccountCountry?.value === "CA" && (
        <>
          <div className="form-group">
            <strong><label htmlFor="">Interac e-transfer Email:</label></strong>
            <input
              type="email"
              className="form-control"
              placeholder="Enter your Interac e-transfer email."
              value={interacEmail}
              onChange={e => handleInputChange(e, "interacEmail")}
              onBlur={e => {
                if (!validateInput(patterns["email"], interacEmail)) {
                 document.querySelector("#ba-emailerror").style.display = "block";
                } else {
                  document.querySelector("#ba-emailerror").style.display = "none";
                }
              }}
            />
             <small id="ba-emailerror" style={{color:"red", display:"none"}}>Invalid email format! Please ensure it is either correctly filled or left empty.</small>
          </div>
          <div className="form-group">
            <strong><label htmlFor="">Routing Number/Transit No/Branch Code:</label></strong>
            <input
              type="number"
              className="form-control"
              placeholder="Enter number"
              value={routingNumber}
              onChange={e => {if (!validateInput(patterns["numbersOnly"], e.target.value)) return;
                handleInputChange(e, "routingNumber");}}
            />
          </div>
        </>
      )}
      <div>
        <a href="#" onClick={e => {
          toggleShowIntDetails(e);
          internationalEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }}>
          {showIntDetails ? (
            <i className="fa fa-caret-up" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          )}
          International transfer details
        </a>
      </div>
      {showIntDetails && (
        <div>
          <div className="alert alert-primary" role="alert">
            If you want to receive international payments, additional details
            about <strong>Account Holder</strong> are necessary.
          </div>
          <div className="form-group">
            <label htmlFor="">IBAN</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your IBAN number"
              value={iban}
              onChange={e => {if (!validateInput(patterns["alphanumeric"], e.target.value)) return;
                handleInputChange(e, "iban");}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Country of residency</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your country of residence"
              value={countryOfResidence}
              onChange={e => {if (!validateInput(patterns["name"], e.target.value)) return;
                handleInputChange(e, "countryOfResidence");}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">State/Region</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your state or region"
              value={state}
              onChange={e =>{if (!validateInput(patterns["name"], e.target.value)) return;
                handleInputChange(e, "state");}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">City</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your city"
              value={city}
              onChange={e => {if (!validateInput(patterns["name"], e.target.value)) return;
                handleInputChange(e, "city");}}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Zip Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your zip code"
              value={zipCode}
              onChange={e => {if (!validateInput(patterns["numbersOnly"], e.target.value)) return;
                handleInputChange(e, "zipCode");}}
            />
          </div>
          <div className="form-group" ref={internationalEndRef}>
            <label htmlFor="">Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter your address"
              value={address}
              onChange={e => {if (!validateInput(patterns["alphanumeric"], e.target.value)) return;
                handleInputChange(e, "address");}}
            />
          </div>
        </div>
      )}
    </div>
    <div className="createoffermodal-addaccount-btns-cont">
      <button
        type="button"
        className="createoffer-modal-addaccount-cancelbutton"
        onClick={e => handleStepChange(e, "one")}
      >
        Back
      </button>
      <button
        type="submit"
        className="createoffer-modal-addaccount-button"
        disabled={isSubmitting}
        onClick={e => onSubmit(e)}
      >
    {isSubmitting ? "Submitting" : "Add Account"}
  </button>
    </div>
  </>
);}

const AddAccountModal = ({
  handleCloseModal,
  countries,
  currencies,
  step,
  accountType,
  bankAccountCountry,
  bankAccountCurrency,
  bankName,
  accountName,
  customBankDetails,
  accountNumber,
  sortCode,
  swiftCode,
  clabe,
  interacEmail,
  iban,
  countryOfResidence,
  state,
  city,
  zipCode,
  address,
  isSubmitting,
  handleStepChange,
  handleInputChange,
  handleCountryChange,
  handleCurrencyChange,
  handleBankNameChange,
  fetchBanks,
  countryBanks,
  isFetchingBanks,
  apiErrors ={},
  onSubmit
}) => {
  const [showIntDetails, setIntDetails] = useState(false); // Initial state
  const internationalEndRef = useRef(null);

  useEffect(() => {
    fetchBanks(bankAccountCountry.value)
    const countryCurrency = countryToCurrrency[bankAccountCountry.value]
    handleCurrencyChange(countryCurrency)
  }, [bankAccountCountry])

  const toggleShowIntDetails = e => {
    e.preventDefault();

    setIntDetails(!showIntDetails);
  };

  return (
    <div className="modal d-flex align-items-center justify-content-center" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content" style={{ minWidth: "350px", maxHeight: "500px", height: "60vh",  overflow: "scroll"}}>
          <button
            type="button"
            className="close-addaccount"
            data-dismiss="modal"
            aria-label="Close"
            onClick={e => handleCloseModal(e)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div>
            <h5 className="modal-title-addaccount" id="exampleModalLongTitle">
              Add Bank Account
            </h5>
          </div>
          {/* Display API errors if any */}
          {Object?.keys(apiErrors).length > 0 && (
            <div className="createoffer-error-alert">
              <ul className="list-unstyled mb-0">
                {Object.values(apiErrors).map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="modal-body bank-account-modal-body" style={{ height: "auto" }}>
            {step === "one" && (
              <StepOne
                accountType={accountType}
                countries={countries}
                currencies={currencies}
                bankAccountCountry={bankAccountCountry}
                bankAccountCurrency={bankAccountCurrency}
                handleCountryChange={handleCountryChange}
                handleCurrencyChange={handleCurrencyChange}
                handleInputChange={handleInputChange}
                handleStepChange={handleStepChange}
                handleCloseModal={handleCloseModal}
                isFetchingBanks={isFetchingBanks}
              />
            )}
  
            {step === "two" && (
              <StepTwo
                accountType={accountType}
                bankAccountCountry={bankAccountCountry}
                bankAccountCurrency={bankAccountCurrency}
                bankName={bankName}
                accountName={accountName}
                customBankDetails={customBankDetails}
                accountNumber={accountNumber}
                sortCode={sortCode}
                swiftCode={swiftCode}
                clabe={clabe}
                interacEmail={interacEmail}
                iban={iban}
                state={state}
                countryOfResidence={countryOfResidence}
                city={city}
                zipCode={zipCode}
                address={address}
                isSubmitting={isSubmitting}
                showIntDetails={showIntDetails}
                handleInputChange={handleInputChange}
                handleStepChange={handleStepChange}
                toggleShowIntDetails={toggleShowIntDetails}
                handleBankNameChange={handleBankNameChange}
                onSubmit={onSubmit}
                internationalEndRef={internationalEndRef}
                countryBanks={countryBanks}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default AddAccountModal;
