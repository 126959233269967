import React from "react";

const UserGrowth = ({ data }) => {
  const verifiedUsersByRegion = data?.data?.verified_users_by_region || {};

  const regionEntries = Object.entries(verifiedUsersByRegion);

  const generatePieData = (key) => {
    const labels = regionEntries.map(([regionName]) => regionName);
    const values = regionEntries.map(([, regionData]) => regionData[key] || 0);
    return {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#8A2BE2"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#8A2BE2"],
        },
      ],
    };
  };

  // Pie chart data for each metric
  const totalUsersData = generatePieData("total_users");
  const percentageIncreaseData = generatePieData("percentage_increase");

  const unverifiedUsers = data?.data?.unverified_users || {
    total_users: 0,
    percentage_increase: 0,
  };
  const verifiedUsers = data?.data?.verified_users || {
    total_users: 0,
    percentage_increase: 0,
  };

  return (
    <div
      style={{
        border: "1px solid gray",
        borderRadius: "8px",
        backgroundColor: "#2e3d62",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <h1 style={{ marginBottom: "16px", color: "#eeebfa" }}>User Growth</h1>
      {/* Summary Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginTop: "16px",
        }}
      >
        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgb(219, 220, 228)",
            padding: "15px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
        >
          <strong>Unverified Users:</strong>
          <ul style={{ marginLeft: "16px" }}>
            <li>Total Users: {unverifiedUsers.total_users}</li>
            <li>
              Percentage Increase:&nbsp;
              <strong
                style={{
                  color:
                    unverifiedUsers.percentage_increase <= 0 ? "red" : "green",
                }}
              >
                {Number(unverifiedUsers.percentage_increase).toFixed(2)}%
              </strong>
            </li>
          </ul>
        </div>
        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgb(224, 225, 232)",
            padding: "15px",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <strong>Verified Users:</strong>
          <ul style={{ marginLeft: "16px" }}>
            <li>Total Users: {verifiedUsers.total_users}</li>
            <li>
              Percentage Increase:{" "}
              <strong
                style={{
                  color:
                    verifiedUsers.percentage_increase <= 0 ? "red" : "green",
                }}
              >
                {Number(verifiedUsers.percentage_increase).toFixed(2)}%
              </strong>
            </li>
          </ul>
        </div>
      </div>
      <br />
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgb(224, 225, 232)",
            padding: "15px",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
          }}
        >
          <strong>Region Percentage Increase:</strong>
          <ul style={{ marginLeft: "16px" }}>
            {Array.from(
              { length: percentageIncreaseData.labels.length },
              (_, index) => (
                <li key={index}>
                  {percentageIncreaseData.labels[index]}:{" "}
                  <strong
                    style={{
                      color:
                        percentageIncreaseData.datasets[0].data[index] <= 0
                          ? "red"
                          : "green",
                    }}
                  >
                    {Number(percentageIncreaseData.datasets[0].data[index]).toFixed(2)}%
                  </strong>
                </li>
              )
            )}
          </ul>
        </div>

        <div
          style={{
            margin: "0",
            width: "50%",
            backgroundColor: "rgba(255,246,225)",
            padding: "15px",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
          }}
        >
          <strong>Total Users by Region:</strong>
          <ul style={{ marginLeft: "16px" }}>
            {Array.from(
              { length: totalUsersData.labels.length },
              (_, index) => (
                <li key={index}>
                  {totalUsersData.labels[index]}:{" "}
                  <strong>{totalUsersData.datasets[0].data[index]}</strong>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserGrowth;
