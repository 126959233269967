import React, { Component } from "react";
import { patchRequest, getRequest } from "../../../../utils/httpRequest";
import DelistOffersModal from "./DelistOffersModal";
import Carousel from "./components/Carousel";
import Tab from "./components/Tab";
import Filters from "./components/Filters";
import Content from "./components/Content";
import "./index.scss";
import { OfferType, ReviewType, FiatProps } from "./Types";
import NoticeMessageModal from "../../Trades/TradeDetail/components/NoticeMessageModal";

interface Props {
  viewType: string;
  currentExternalProfileId: string;
  currentUserAltCurrency: string;
}

interface State {
  isActiveTab: string;
  viewType: string;
  currentExternalProfileId: string;
  userAltCurrency: string;
  offers: OfferType[];
  paymentMethods: string[];
  currencies: string[];
  countries: string[];
  totalPages: number;
  currentPage: number;
  itemsPerPage: number;
  fiatCurrencies: FiatProps[];
  reviews: ReviewType[];
  [x: number]: string;
  filters: {
    paymentMethod: string;
    country: string;
    currency: string;
    price: string;
  };
  currencyPaymentMethods: string[];
}

class AllOffers extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isActiveTab: props.tradeType || "buy",
      viewType: props.viewType,
      currentExternalProfileId: props?.currentExternalProfileId,
      userAltCurrency: props.currentUserAltCurrency,
      offers: [],
      paymentMethods: [],
      currencies: [],
      countries: [],
      reviews: [],
      fiatCurrencies: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      filters: {
        paymentMethod: "",
        country: "",
        currency: props.currentUserAltCurrency,
        price: "",
      },
      currencyPaymentMethods: [],
    };
  }

  componentDidMount() {
    this.getAllReviews();
    this.getOffers();
    this.getOfferFilters();
    this.getFiatCurrencies();
    this.getCurrencyPaymentMethod();
    this.checkScamAlert();
  }

  getFiatCurrencies = () => {
    getRequest("/p2p_trades/currencies").then((response) => {
      const fiatCurrencies = response.map((currency) => ({
        code: currency.code,
        name: currency.name,
      }));

      this.setState({
        fiatCurrencies,
      });
    });
  };

  onChangeTab = (e, isActiveTab) => {
    e.preventDefault();

    this.setState({ isActiveTab, currentPage: 1 }, () => {
      this.getOffers();
      this.getOfferFilters();
      this.getCurrencyPaymentMethod();
    });

    const baseUrl = `${window.location.origin}/p2p_trades/offers/search`;

    const newUrl = `${baseUrl}?offer_type=${isActiveTab}`;

    // Use history.pushState to update the URL without reloading the page
    window.history.pushState({}, "", newUrl);
  };

  onSelectFilter = (key, value) => {
    const { filters } = this.state;
    const updatedFilters = { ...filters, [key]: value };

    this.setState({ filters: updatedFilters, currentPage: 1 }, () => {
      this.getOffers();
      this.getCurrencyPaymentMethod();
    });
  };

  checkScamAlert = () => {
    const baseURL = `${window.location.protocol}//${window.location.host}`;
  
    getRequest(`${baseURL}/api/v1/scam_alert`)
      .then((response) => {
        if (response.message.includes("not_yet")) {
          this.showNoticeMessageModal();
          console.log("Scam alert is 'not_yet'.");
        } else {
          console.log(`Scam alert is '${response.message}'.`);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
    

  closeModal = () => {
    const delistOfferModal = document.querySelector("#delistOffersModal");
    (delistOfferModal as any).style.display = "none";
  };

  showNoticeMessageModal = () => {
    const noticeMessageModal = document.querySelector(
      "#noticeMessageModal"
    ) as HTMLElement;
    if (noticeMessageModal) {
      noticeMessageModal.style.display = "flex";
    }
  };

  closeNoticeMessageModal = () => {
    const noticeMessageModal = document.querySelector(
      "#noticeMessageModal"
    ) as HTMLElement;
    if (noticeMessageModal) {
      noticeMessageModal.style.display = "none";
    }
  };

  handlePageChange = (action) => {
    const { currentPage, totalPages } = this.state;

    let newPage;

    // Determine new page based on action
    if (action === "prev") {
      newPage = currentPage > 1 ? currentPage - 1 : 1;
    } else if (action === "next") {
      newPage = currentPage < totalPages ? currentPage + 1 : totalPages;
    } else if (typeof action === "number") {
      newPage = action; // Direct page number click
    }

    // Set the new page if it's different
    if (newPage !== currentPage) {
      this.setState({ currentPage: newPage }, () => {
        this.getOffers();
      });
    }
  };

  changeOfferStatus = (offer, status) => {
    const endpoint = offer.active ? "deactivate" : "activate";

    offer.active = status;

    patchRequest(`/p2p_trades/offers/${offer.id}/${endpoint}`, {
      active: status,
      is_featured: false,
      offer_id: offer.id,
    })
      .then((response) => {
        if (!status) {
          const delistOfferModal = document.querySelector("#delistOffersModal");
          (delistOfferModal as any).style.display = "flex";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleReload = () => {
    window.location.reload();
  };

  getAllReviews = () => {
    getRequest("/p2p_trades/reviews/all_profiles_reviews")
      .then((response) => {
        this.setState({
          reviews: response.data,
        });
      })
      .catch((error) => {
        console.error("Error fetching reviews:", error);
      });
  };

  getOfferFilters = () => {
    const { isActiveTab } = this.state;

    getRequest(`/api/v2/p2p_trades/offer_filters?is_active_tab=${isActiveTab}`)
      .then((response) => {
        this.setState({
          paymentMethods: response.payment_methods,
          currencies: response.currencies,
          countries: response.countries,
        });
      })
      .catch((error) => {
        console.error("Error fetching offer filters:", error);
      });
  };

  getOffers = () => {
    const {
      isActiveTab,
      currentPage,
      viewType,
      filters: { currency, country, paymentMethod },
    } = this.state;

    const baseUrl =
      viewType === "listUserOffers"
        ? "/api/v2/p2p_trades/my_offers"
        : "/api/v2/p2p_trades/offers";

    return getRequest(
      `${baseUrl}?is_active_tab=${isActiveTab}&currency=${currency}&country=${country}&payment_method=${encodeURIComponent(
        paymentMethod
      )}&page=${currentPage}`
    )
      .then((response) => {
        this.setState({
          offers: response.hits,
          totalPages: response.total_pages,
        });
      })
      .catch((error) => {
        console.error(`Error fetching ${isActiveTab} offers:`, error);
      });
  };

  getCurrencyPaymentMethod = () => {
    const {
      isActiveTab,
      viewType,
      filters: { currency },
    } = this.state;

    const baseUrl =
      viewType === "listUserOffers"
        ? "/api/v2/p2p_trades/my_offers"
        : "/api/v2/p2p_trades/offers";

    return getRequest(
      `${baseUrl}/currency_payment_method?is_active_tab=${isActiveTab}&currency=${currency}`
    )
      .then((response) => {
        this.setState({
          currencyPaymentMethods: response,
        });
      })
      .catch((error) => {
        console.error(`Error fetching ${isActiveTab} offers:`, error);
      });
  };

  render() {
    const {
      isActiveTab,
      viewType,
      filters,
      totalPages,
      currentPage,
      reviews,
      countries,
      currencies,
      paymentMethods,
      offers,
      fiatCurrencies,
      currentExternalProfileId,
      currencyPaymentMethods,
    } = this.state;

    return (
      <div className="p2p-container">
        <div id="scam-alert-cont">
          <NoticeMessageModal onClose={this.closeNoticeMessageModal} />
        </div>
        <Carousel />
        <div className="p2p-parent-section">
          <Tab isActiveTab={isActiveTab} onChangeTab={this.onChangeTab} />
          <Filters
            countries={countries}
            currencies={currencies}
            paymentMethods={paymentMethods}
            filters={filters}
            onSelectFilter={this.onSelectFilter}
            handleReload={this.handleReload}
            fiatCurrencies={fiatCurrencies}
            currencyPaymentMethods={currencyPaymentMethods}
          />
          <Content
            offers={offers}
            totalPages={totalPages}
            currentPage={currentPage}
            reviews={reviews}
            currentExternalProfileId={currentExternalProfileId}
            changeOfferStatus={this.changeOfferStatus}
            handlePageChange={this.handlePageChange}
          />
          <div id="delistOffersModal">
            <DelistOffersModal
              viewType={viewType}
              closeModal={this.closeModal}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AllOffers;
