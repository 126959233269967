import React from "react";

const TradeStatus = ({
  isDisputed,
  tradeData,
  isSystemCancelled,
}) => {
  const getTradeHeading = () => {
    
    if (tradeData.external_status === "payment_accepted") {
      return "Trade Accepted";
    } else if (isDisputed) {
      return "Trade Disputed";
    } else if (tradeData.external_status === "completed") {
      return "Trade Completed";
    } else if (tradeData.external_status === "cancelled" && !isSystemCancelled && tradeData.status !== "expired") {
      return "Trade Cancelled";
    } else if (isSystemCancelled || tradeData.status === "expired") {
      return "Trade Expired";
    } else if (tradeData.external_status === "payment_paid") {
      return "Trade Paid";
    } else if (tradeData.external_status === "pending_payment") {
      return "Trade Started";
    }
  };

  // Function to get dynamic class based on trade status
  const getTradeStatusClass = () => {
    if (tradeData.external_status === "payment_paid"  || tradeData.external_status === "payment_accepted" || tradeData.external_status === "completed") {
      return "tradestatus-paid";
    } else if (isDisputed) {
      return "tradestatus-disputed";
    } else if ((tradeData.external_status === "cancelled" || isSystemCancelled) && isSystemCancelled) {
      return "tradestatus-expired";
    }
    else if ((tradeData.external_status === "cancelled" || isSystemCancelled) && !isSystemCancelled) {
      return "tradestatus-cancelled";
    } else if (tradeData.external_status === "pending_payment") {
      return "tradestatus-started";
    } else {
      return "tradestatus-default"; // Default class if no condition is met
    }
  };

  return (
    <div className="tadestatus-cont">
      <div className={`tradestatus-indicator ${getTradeStatusClass()}`}>

      </div>
      <span className="tradestatus-info">
        {getTradeHeading()}
      </span>
    </div>
  );
};

export default TradeStatus;
