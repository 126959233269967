import React, { Component } from "react";
import moment from "moment";
import { getRequest, patchRequest } from "../../utils/httpRequest";
import Filters from "./Filters";
import ActivityPagination from "../AccountActivity/ActivityPagination";
import Table from "./Table";

class MasterWalletFees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transfers: [],
      filters: {
        startDate: moment("1990-01-01"),
        endDate: moment(),
        type: "",
        status: ""
      },
      loading: true,
      currentPage: 1,
    };
  }

  componentDidMount() {
    getRequest("/crypto_transfers/trade_fees/master_fees.json").then((response) => {
      const sortedTransfers = response.transfers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      this.setState({
        transfers: sortedTransfers,
        totalPages: response.total_pages,
        loading: false,
      });
    });
  }

  onSelectFilter = (key, value) => {
    const { filters } = this.state;

    const updatedFilters = { ...filters, [key]: value };

    this.setState({ filters: updatedFilters });
  };

  handlePageChange = (e, currentPage) => {
    e.preventDefault();
    this.setState({ currentPage }, () => this.getTransfers());
  };

  clearFilters = (e) => {
    e.preventDefault();

    this.setState({ filters: {
      startDate: moment("1990-01-01"),
      endDate: moment(),
      type: "",
      status: ""
    } }, () =>
      this.filterTransfers(e)
    );
  };

  filterTransfers = (e) => {
    e.preventDefault();

    this.setState({ loading: true, currentPage: 1 }, () => this.getTransfers());
  }

  getTransfers() {
    const {
      filters: {startDate, endDate, type, status},
      currentPage,
    } = this.state;

    const url =
      `/crypto_transfers/trade_fees/master_fees.json?[external_status]=${status}&[created_at_gteq]=${startDate}&[created_at_lteq]=${endDate}&[type]=${type}&page=${currentPage}`;

    getRequest(url).then((response) => {
      const sortedTransfers = response.transfers.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      this.setState({
        transfers: sortedTransfers,
        totalPages: response.total_pages,
        loading: false,
      });
    });
  };

  render() {
    const { transfers, currentPage, totalPages, filters, loading } = this.state;

    return (
      <div className="account-activity pb-5">
        {
          <Filters
            filters={filters}
            filterTransfers={this.filterTransfers}
            onSelectFilter={this.onSelectFilter}
            clearFilters={this.clearFilters}
          />
        }
        <Table
          transfers={transfers}
          loading={loading}
        />
        {totalPages > 1 && (
          <ActivityPagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
          />
        )}
      </div>
    );
  }
}

export default MasterWalletFees;
