import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../../utils/httpRequest";
import { calculateFiatTradeFee, calculateTradeFee } from "./helpers";
import Header from "./components/Header";
import TradeInput from "./components/TradeInput";
import Rate from "./components/Rate";
import Terms from "./components/Terms";
import Tags from "./components/Tags";

interface Offer {
  id: number;
  margin: number;
  fiatCurrency: {
    code: string;
    rateToUsd: number;
    externalRates: { sellRate: number; buyRate: number }[];
  };
  availableAmount: string;
  offerTypeField: string;
  fixedPrice: number;
  isFixedPrice: boolean;
  cryptoCurrency: { code: string };
  paymentMethod: { id: number; name: string; slug: string };
  profile: { id: number };
  rangeMin: number;
  rangeMax: number;
  offerTerms: string;
  tradeRate: string;
  tags: { label: string }[];
}

const CreateTrade = ({ offerId, tradeFee, availableBalance }) => {
  useEffect(() => {
    getOfferById();
    getBankAccounts();
    getUserBankAccounts();
    getAllReviews();
  }, []);

  // @ts-ignore
  const [offer, setOffer] = useState<Offer>({});
  const [selectedValue, setSelectedValue] = useState("");
  const [sellerRate, setSellerRate] = useState(0.0);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [userBankAccounts, setUserBankAccounts] = useState([]);
  const [fiatAmount, setFiatAmount] = useState("");
  const [cryptoAmount, setCryptoAmount] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [amountValidationMessage, setAmountValidationMessage] = useState("");
  const [rangeValidationMessage, setRangeValidationMessage] = useState("");
  const [balanceValidationMessage, setBalanceValidationMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [highlightUSD, setHighlightUSD] = useState(false);
  const [highlightFIAT, setHighlightFIAT] = useState(false);
  const [offerTypeDisplay, setOfferTypeDisplay] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [reviews, setReviews] = useState([]);

  const handleSelectChange = (event) => {
    setHasError(false);
    setSelectedValue(event.target.value);
  };
  const brokersRate = (rateToUsd, margin) => {
    return (
      (parseFloat(rateToUsd) * parseFloat(margin)) / 100 +
      parseFloat(rateToUsd)
    ).toFixed(2);
  };

  const getOfferById = async () => {
    try {
      const response = await getRequest(`/p2p_trades/offers/${offerId}`);
      processOfferResponse(response);
    } catch (error) {
      console.error("Error fetching offer:", error);
    }
  };

  const processOfferResponse = (response) => {
    setOffer(response);
    const margin = parseFloat(response.margin);
    const marketRate = getMarketRate(response);
    const offerTypeDisplay = getOfferTypeDisplay(response.offerTypeField);
    const tradersRate = getTradersRate(response, marketRate, margin);

    setOfferTypeDisplay(offerTypeDisplay);
    setSellerRate(tradersRate);
  };

  const getMarketRate = (response) => {
    const hasExternalRate = response.fiatCurrency?.externalRates?.length > 0;

    if (response.isFixedPrice) {
      return response.fixedPrice;
    }

    if (hasExternalRate) {
      return response.offerTypeField === "sell"
        ? response.fiatCurrency.externalRates[0].sellRate
        : response.fiatCurrency.externalRates[0].buyRate;
    }

    return response.fiatCurrency?.rateToUsd || 1.0; // Default to average rate if no external rates are present
  };

  const getOfferTypeDisplay = (offerTypeField) => {
    return offerTypeField === "sell" ? "buy" : "sell";
  };

  const getTradersRate = (response, marketRate, margin) => {
    const rate = parseFloat(response.tradeRate);
    if (rate > 0) {
      return parseFloat(rate.toFixed(2));
    }

    if (response.isFixedPrice) {
      return response.fixedPrice;
    }

    return brokersRate(marketRate, margin);
  };

  const getBankAccounts = () => {
    getRequest(`/p2p_trades/offers/${offerId}/bank_account`).then(
      (response) => {
        const bankAccount = response?.bank_account?.data;
        if (bankAccount) {
          const bankAccounts = [bankAccount].map((account) => ({
            label: `${account.accountHolderName}, ${account.fiatCurrency.code}, ${account.bankName === "Other" ? account.customBankDetails : account.bankName }, ${account.accountNumber}`,
            value: account.id,
          }));
          setBankAccounts(bankAccounts);
        }
      }
    );
  };

  const getUserBankAccounts = () => {
    getRequest("/p2p_trades/bank_accounts").then((response) => {
      const bankAccounts = response.map((account) => ({
        label: `${account.accountHolderName}, ${account.fiatCurrency.code}, ${account.bankName === "Other" ? account.customBankDetails : account.bankName }, ${account.accountNumber}`,
        value: account.id,
      }));

      setUserBankAccounts(bankAccounts);
    });
  };

  const onFiatChange = (amount) => {
    //remove any letter other than numbers and decimals
    amount = amount.replace(/[^0-9.]/g, "");
    setHasError(false);
    setHighlightFIAT(false);
    setValidationMessage(" ");
    if (amount === "" || amount === "." || !amount) {
      setFiatAmount("");
      setCryptoAmount("");
      return;
    } 
    
    if (
      (parseFloat(amount) < offer.rangeMin) || (parseFloat(amount) > offer.rangeMax)
    ) {
      setHasError(true);
      setHighlightFIAT(true);
      setValidationMessage(`You can't ${offerTypeDisplay} an amount lesser or greater than the trade limits.`)
    } else {
      setHasError(false);
      setHighlightFIAT(false);
      setValidationMessage(" ");
    }

    setFiatAmount(amount);
    const cryptoAmountCalculated = parseFloat(amount) / sellerRate;
    setCryptoAmount(cryptoAmountCalculated.toFixed(2));
  };

  const onCryptoChange = (amount) => {
    setHasError(false);
    //remove any letter other than numbers and decimals
    amount = amount.replace(/[^0-9.]/g, "");

    if (amount === "" || amount === "." || !amount) {
      setFiatAmount("");
      setCryptoAmount("");
      return;
    }

    if (
      offer.offerTypeField === "buy" &&
      (parseFloat(amount) + parseFloat(calculateTradeFee(cryptoAmount, tradeFee).toFixed(2))) > parseFloat(availableBalance)
    ) {
      setHasError(true);
      setHighlightUSD(true);
      setValidationMessage("The amount (plus fee) you want to sell exceeds your account balance.");
    } else if (
      offer.offerTypeField === "buy" &&
      (parseFloat(amount) + parseFloat(calculateTradeFee(cryptoAmount, tradeFee).toFixed(2))) > parseFloat(offer.availableAmount)
    ) {
      setHasError(true);
      setHighlightUSD(true);
      setValidationMessage("The amount (plus fee) you want to sell exceeds the target amount that the broker intends to buy.");
    } else {
      setHasError(false);
      setHighlightUSD(false);
      setValidationMessage("");
    }

    if (
      offer.offerTypeField === "sell" &&
      parseFloat(amount) > parseFloat(offer.availableAmount)
    ) {
      setHasError(true);
      setHighlightUSD(true);
      setValidationMessage("The amount you want to buy exceeds the target amount that the broker intends to sell.");
    }

    setCryptoAmount(amount);
    const fiatAmountCalculated = parseFloat(amount) * sellerRate;
    setFiatAmount(fiatAmountCalculated.toFixed(2));
  };

  const isFiatAmountValid = (fiatAmount, rangeMin, rangeMax) => {
    if (fiatAmount == null) {
      return false;
    }

    const amount = parseFloat(fiatAmount);

    return amount >= rangeMin && amount <= rangeMax;
  };

  const getAllReviews = () => {
    getRequest("/p2p_trades/reviews/all_profiles_reviews")
      .then((response) => {
          setReviews(response.data);
      })
      .catch((error) => {
          console.error("Error fetching reviews:", error);
      });
  };

  const onSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();

    if (!isFiatAmountValid(fiatAmount, offer.rangeMin, offer.rangeMax)) {
      setHasError(true);
      setValidationMessage(
        `The trade limit is ${offer?.rangeMin} — ${offer?.rangeMax} ${offer?.fiatCurrency.code}`
      );
      setSubmitting(false);
      return;
    }

    if (offer.offerTypeField === "buy" && !selectedValue) {
      setHasError(true);
      setValidationMessage("Please state your payment details");
      setSubmitting(false);
      return;
    }

    // Does not submit if there is an error
    if (hasError === true) {
      setSubmitting(false);
      return;
    }

    setHasError(false);
    setValidationMessage("");
    const account = bankAccounts[0]

    const params = {
      payment_method_id: offer.paymentMethod?.id,
      payment_method_name: offer.paymentMethod?.name,
      offer_id: offer.id,
      offer_user_external_profile_id: offer.profile?.id,
      fiat: parseFloat(fiatAmount),
      fiat_currency_code: offer.fiatCurrency.code,
      external_rates: offer.fiatCurrency.externalRates || [],
      crypto_amount: parseFloat(cryptoAmount),
      crypto_currency_code: offer.cryptoCurrency.code,
      trade_type: offerTypeDisplay,
      offer_terms: offer.offerTerms,
      trade_rate: offer.tradeRate,
      is_fixed_price: offer.isFixedPrice,
      seller_payment_method_detail: offerTypeDisplay === "buy" ? bankAccounts[0]?.label : selectedValue,
    };
    postRequest("/p2p_trades/trades", params)
      .then((response) => {
        const trade_id = response["trade"]["id"];
        location.href = `/p2p_trades/trades/${trade_id}`;
      })
      .catch((error) => {
        setSubmitting(false);
        let message;
        if (error.data?.errors?.length > 0) {
          message = error.data?.errors[0]?.error;
        } else if (error.data?.errors?.data?.errors?.length > 0) {
          message = error.data?.errors?.data?.errors[0]?.error;
        }
        if (message) {
          setValidationMessage(message);
          setHasError(true);
        }
      });
  };

  return (
    <>
      <Header
        offer={offer}
        offerTypeDisplay={offerTypeDisplay}
        bankAccounts={bankAccounts}
      />
      <div className="createtrade-cont">
        <div className="tradeprofile-details-section">
          <Rate
            offer={offer}
            offerTypeDisplay={offerTypeDisplay}
            sellerRate={sellerRate}
            reviews={reviews}
          />
          <Terms offerTerms={offer?.offerTerms} />
          <Tags tags={offer?.tags} />
        </div>
        <div className="trade-form-section">
          <TradeInput
            offer={offer}
            hasError={hasError}
            validationMessage={validationMessage}
            offerTypeDisplay={offerTypeDisplay}
            highlightUSD={highlightUSD}
            highlightFIAT={highlightFIAT}
            cryptoAmount={cryptoAmount}
            onCryptoChange={onCryptoChange}
            availableBalance={availableBalance}
            fiatAmount={fiatAmount}
            onFiatChange={onFiatChange}
            selectedValue={selectedValue}
            sellerRate={sellerRate}
            tradeFee={tradeFee}
            userBankAccounts={userBankAccounts}
            handleSelectChange={handleSelectChange}
            submitting={submitting}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default CreateTrade;
