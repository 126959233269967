import React from "react";

const Head = () => (
  <thead class="table-header rounded-top">
    <tr>
      <th>Date</th>
      <th>Amount</th>
      <th>Type</th>
      <th>Description</th>
      <th>CryptoTransfer ID</th>
      <th>Status</th>
      <th></th>
    </tr>
  </thead>
);

export default Head;
