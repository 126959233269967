import React from "react";
interface Props {
  onClickEdit: (e: any, step: string) => void;
  data: any;
}

const PersonalDetails: React.FC<Props> = ({ data, onClickEdit }) => (
  <div className="card-inner-container">
    <h6 className="card-sub-title">Personal Details</h6>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Title</label>
      <div className="col-12 col-md-9">
        <span>{data.salutation}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Last Name</label>
      <div className="col-12 col-md-9">
        <span>{data.lastName}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">First Name</label>
      <div className="col-12 col-md-9">
        <span>{data.firstName}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Middle Name</label>
      <div className="col-12 col-md-9">
        <span>{data.middleName}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Name On Card</label>
      <div className="col-12 col-md-9">
        <span>{data.nameOnCard}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Occupation</label>
      <div className="col-12 col-md-9">
        <span>{data.occupation}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Phone Number</label>
      <div className="col-12 col-md-9">
        <span>{data.phoneNumber}</span>
      </div>
    </div>
    <div className="form-group row">
      <label className="col-12 col-md-3 form-label card-sub-title">Gender</label>
      <div className="col-12 col-md-9">
        <span>{data.gender}</span>
      </div>
    </div>
    <div className="ml-auto">
      <a
        className="btn btn-purple"
        href="#"
        onClick={(e) => onClickEdit(e, "personal")}
      >
        Edit <i className="fa fa-pen"></i>
      </a>
    </div>
  </div>
);

export default PersonalDetails;
