import React from "react";
import Card from ".././Card";
import "../../../P2PTrades/Offers/index.scss";

const List = ({ cards }) => {
  return (
    <div className="container-fluid px-2 px-md-4">
      <div className="card shadow-sm rounded-lg">
        <div className="card-header d-flex flex-column justify-content-center align-items-start">
          <h5 className="create-trade-page-title"><i className="fa fa-credit-card"></i>{" "}Debit Card </h5>
          <span>Your Card Information.</span>
        </div>
        <div className="card-body">
          <div className="row">
            {cards.map((card, i) => (
              <Card key={i} card={card} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
