import React, { useEffect } from "react";

const Body = ({ transfers }) => {
  useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const handleRowClick = (e, transferId) => {
    if (!e.target.closest("td:last-child")) {
      window.location.href = `/crypto_transfers/${transferId}`;
    }
  };

  return (
    <tbody className="table-body">
      {transfers.map((transfer, i) => (
        <tr
          key={transfer.id}
          className="cursor-pointer"
          onClick={(e) => handleRowClick(e, transfer.crypto_transfer_id)}
        >
          <td className="pl-2 ml-1">
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Date:</span>
              <span>{new Date(transfer.created_at).toLocaleDateString()}</span>
            </div>
          </td>
          <td>
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Amount:</span>
              <span>{transfer.amount}</span>
            </div>
          </td>
          <td>
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Type:</span>
              <span className="activity-type-text">{transfer.transfer_type}</span>
            </div>
          </td>
          <td className="expandable-description">
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Description:</span>
              <span>
                {transfer.description}
              </span>
            </div>
          </td>
          <td className="expandable-description">
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">CryptoTransfer ID:</span>
              <span>
                <a href={`/crypto_transfers/${transfer.crypto_transfer_id}`} className="unlink">
                  {transfer.crypto_transfer_id}
                </a>
              </span>
            </div>
          </td>
          <td className={transfer.active_class_by_status}>
            <div class="font-weight-bold d-flex justify-content-between">
              <span class="show-from-tab">Status:</span>
              <span>{transfer.external_status}</span>
            </div>
          </td>
          <td className="actions trash">
            <span>
              <a className="btn-link" href={`/crypto_transfers/${transfer.crypto_transfer_id}`}>
                View <i className="fa fa-arrow-right"></i>
              </a>
            </span>
          </td>
        </tr>
      ))}
      {transfers.length === 0 && (
        <tr>
          <td colSpan={8} className="text-center">
            Currently you have no activity or clear your filters.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default Body;
