import React, { Component } from "react";
import { Formik, Form } from "formik";
import toastr from "toastr";
import CardTypes from "./components/CardTypes";
import Header from "./components/Header";
import PersonalDetails from "./components/PersonalDetails";
import IdentificationDetails from "./components/IdentificationDetails";
import MailingDetails from "./components/MailingDetails";
import Preview from "./components/Preview";
import { postRequest } from "../../../../utils/httpRequest";
import { formErrors } from "../../../../utils/formErrors";
import {
  cardRequestParams,
  formattedApiErrors,
  stepFromError,
} from "./helpers";
import { CardType } from "./Types";
import "./index.scss";
import moment from "moment";

interface Props {
  params: {
    availableBalance: number;
    cardTypes: CardType[];
    couponCode: string;
    user: {
      id: string;
      firstName: string;
      lastName: string;
      isQAUser: boolean;
      dateOfBirth: boolean;
    };
  };

  isMultiRequest: boolean;
}

interface State {
  step: "cardType" | "personal" | "identification" | "mailing" | "preview";
  cardType: string;
  cardTypes: CardType[];
  couponCode: string;
  submitting: boolean;
  isCouponClaimable: boolean;
  apiErrors?: any;
}

class NewCard extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      step: "cardType",
      cardType: "plastic",
      couponCode: "",
      submitting: false,
      cardTypes: props.params?.cardTypes,
      isCouponClaimable: false,
      apiErrors: {},
    };
  }

  handlePreviousStepChange = (step) => {
    this.setState({ step });
  };

  handleSetStep = (step) => {
    this.setState({ step });
  };

  handleNextStepChange = (validateForm, step) => {
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        window.scrollTo(0, 0);
        this.setState({ step });
      }
    });
  };

  updateCouponCode = (couponCode) => {
    this.setState({ couponCode });
  };

  onSelectCardType = (e, cardType) => {
    e.preventDefault();

    this.setState({ cardType });

    this.handleSetStep("mailing");
  };

  submit = (values) => {
    const { cardType } = this.state;
    this.setState({ submitting: true });
  
    const params = {
      card_request : {
      first_name: values.first_name,
      last_name: values.last_name,
      name_on_card: values.name_on_card,
      address: values.address,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
      country: values.country,
      card_type: cardType,
    }
  };
  
  
    postRequest("/api/v1/cards/requests", params, {
      "Content-Type": "application/json",
    })
      .then((response) => {
        window.location.href = `/cards/requests/${response.card_request_id}`;
      })
      .catch((error) => {
        console.log(error);
        this.setState({ submitting: false });
  
        if (error.data?.errors) {
          const errors = formErrors(error.data.errors);
  
          this.setState({
            apiErrors: formattedApiErrors(errors),
            step: stepFromError(error.data.errors),
          });
        }
  
        toastr.error(
          error?.error || error?.data?.error || "Something went wrong"
        );
      });
  };  

  render() {
    const {
      params: { user, availableBalance },
      isMultiRequest,
    } = this.props;
    const { step, cardTypes, cardType, submitting, apiErrors } = this.state;

    return (
      <div className="w-100">
        <div className="create-offer-title">
          <h2 className="create-trade-page-title ml-3 mt-3"> 
          <i className="fa fa-credit-card"></i>
          {" "}
          Apply for a Card</h2>
        </div>
        <Formik
          initialValues={{
            first_name: user.firstName,
            last_name: user.lastName,
            name_on_card: `${user.firstName} ${user.lastName}`,
            address: "",
            city: "",
            state: "",
            country: "",
            postal_code: "",
          }}
          onSubmit={(values) => this.submit(values)}
        >
          {({ setFieldValue, errors, values, handleChange, validateForm }) => (
            <Form className="form mt-5">
              {step === "cardType" && (
                <CardTypes
                  availableBalance={availableBalance}
                  cardTypes={cardTypes}
                  isQAUser={user.isQAUser}
                  onSelectCardType={this.onSelectCardType}
                  updateCouponCode={this.updateCouponCode}
                />
              )}

              <div className="steps card shadow-sm rounded-lg">
                {step === "mailing" && (
                  <MailingDetails
                    errors={errors}
                    apiErrors={apiErrors}
                    setFieldValue={setFieldValue}
                    selectedCountry={values.country}
                    validateForm={validateForm as any}
                    handleNextStepChange={this.handleNextStepChange}
                    handlePreviousStepChange={this.handlePreviousStepChange}
                    isMultiRequest={isMultiRequest}
                  />
                )}
                {step === "preview" && (
                  <Preview
                    data={values}
                    handleSetStep={this.handleSetStep}
                    cardType={cardType}
                    cardTypes={cardTypes}
                    isMultiRequest={isMultiRequest}
                    submitting={submitting}
                  />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default NewCard;
