import React, { useState } from "react";
import * as cardImage from "../../images/Gold-Elegant-Business-Card.png";
import { formatCardNumber, maskCardNumber } from "./helpers";
import "../../styles/card.scss";
import { cardImages } from "../../Requests/New/helpers";

const Card = ({ card }) => {
  const [viewAllNumbers, setViewAllNumbers] = useState(true);

  const cardNumber = card.card_number;

  const toggleViewAllNumbers = (e, val) => {
    e.preventDefault();

    setViewAllNumbers(val);
  };

  return (
    <div className="dbt-ctn">
      <div className="overlay">
        {viewAllNumbers ? (
          <p className="card-number">{formatCardNumber(cardNumber)} </p>
        ) : (
          <p className="card-number">{maskCardNumber(cardNumber)} </p>
        )}
        {viewAllNumbers ? (
          <p className="link">
            <a href="#" onClick={(e) => toggleViewAllNumbers(e, false)}>
              <u>Hide Card Number</u>
            </a>
          </p>
        ) : (
          <p className="link">
            <a href="#" onClick={(e) => toggleViewAllNumbers(e, true)}>
              <u>Show Card Number</u>
            </a>
          </p>
        )}
        <p className="expires-in">{card.status}</p>
        <p className="card-holder-name">{`${card.first_name} ${card.last_name}`}</p>
      </div>
      <img src={cardImages[card.card_type]} alt="" />
      <div className="card-details-actions">
        <a
          className="btn btn-outline-purple mb-3" style={{background: "#e2e3ea"}}
          href={`/cards/details/${card.id}`}
        >
          <i className="fa fa-eye"></i>{" "}
          View Card Details
        </a>
        {card.status === "active" && (
          <a
            className="btn btn-outline-purple mb-3" style={{background: "#e2e3ea"}}
            href={`/cards/${card.id}/card_loads/new`}
          >
            <i className="fa fa-arrow-right"></i> {" "}
            Load Card
          </a>
        )}
      </div>
    </div>
  );
};

export default Card;
