import React from "react";
import Head from "./Head";
import Body from "./Body";

const Table = ({ transfers, storeId, removeTransfer, loading }) => (
  <div className="container-fluid pt-4">
    <div className="row p-0 pb-4">
      <div className="col-md-12 p-0">
        <div class="shadow-sm rounded-lg table-responsive">
          <table className="table table-striped table-hover table-responsive w-100 d-block d-md-table custom-mobile-table">
            <Head />
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                Loading...
              </div>
            ) : (
              <Body
                transfers={transfers}
                storeId={storeId}
                removeTransfer={removeTransfer}
              />
            )}
          </table>
        </div>
      </div>
    </div>
  </div>
);

export default Table;
