import React, { Component } from "react";
import Modal from "react-modal";
import { AxiosResponse, AxiosError } from "axios";
import { postRequest } from "../../../../../../utils/httpRequest";

interface Props {
  cardType: string;
  showCouponModal: boolean;
  handleShowCouponModal: (val: boolean) => void;
  honorCouponRedeemSuccess: (response) => void;
}

interface State {
  couponCode: string;
  successMessage: string;
  claimable: boolean;
  submitting: boolean;
  error: string;
}

const customStyles = {
  content: {
    top: "50%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    zIndex: 1000,
    transform: "translate(-50%, -50%)",
  },
};

class CouponModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      successMessage: "",
      claimable: null,
      submitting: false,
      error: "",
    };
  }

  handleChange = (e) => {
    this.setState({ error: "", claimable: false });
    this.setState({ couponCode: e.target.value });
  };

  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  onSubmit = () => {
    const { cardType } = this.props;
    const { couponCode } = this.state;

    this.setState({ submitting: true, error: "" });

    postRequest("/api/v1/cards/coupon_claimabilities", {
      coupon_code: couponCode,
      card_type: cardType,
    })
      .then((response) => {
        this.setState({
          submitting: false,
          successMessage: "Coming right up...",
        });

        this.props.honorCouponRedeemSuccess(response);
      })
      .catch((error) => {
        console.error("Error fetching coupon:", error);
        this.setState({
          submitting: false,
          error: error?.data?.error,
        });
      });
  };

  render() {
    const { showCouponModal, handleShowCouponModal } = this.props;

    const { couponCode, successMessage, claimable, submitting, error } =
      this.state;

    return (
      <Modal
        isOpen={showCouponModal}
        style={customStyles}
        className="coupon-modal"
      >
        <div>
          <h4 className="create-trade-page-title ui center aligned">Add gift / Promo code</h4>
          <br />
          <form className="ui form" onSubmit={this.onSubmit}>
            <div className="ui center transparent inverted input">
              <input
                autoFocus
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                className="coupon-code form-control"
                placeholder="Type your code..."
                spellCheck="false"
                type="text"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
              />
            </div>
          </form>

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {claimable && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}

          <br />
          <div className="center">
            <button
              type="button"
              className="btn btn-light"
              onClick={(e) => {
                e.preventDefault();
                handleShowCouponModal(false);
              }}
            >
              Cancel
            </button>
            <button
              className={submitting ? "loading" : "btn store-paidbtn"}
              style={{ marginLeft: "1em" }}
              disabled={submitting || couponCode.length < 3}
              onClick={this.onSubmit}
            >
              <i className="gift icon" />
              {submitting ? "Redeeming..." : "Redeem"}
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CouponModal;
