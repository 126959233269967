import React, { useState, useEffect } from "react";
import { getRequest, patchRequest } from "../../utils/httpRequest";
import UserGrowth from "./UserGrowth";
import TransactionsGrowth from "./TransactionsGrowth";
import Products from "./Products";
import Referrals from "./Referrals";
import SingleTotals from "./SingleTotals";

const PerformanceDashboard = () => {
  const [userGrowthData, setUserGrowthData] = useState({});
  const [transactionsGrowth, setTransactionsGrowth] = useState({});
  const [products, setProducts] = useState({});
  const [referrals, setReferrals] = useState({});
  const [discardedUsers, setDiscardedUsers] = useState({});
  const [unsubscribedEmails, setUnsubscribedEmails] = useState({});
  const [activeRange, setActiveRange] = useState("1W");
  const [appDownloads, setAppDownloads] = useState({});

  const setRange = (range) => {
    setActiveRange(range);
  };
  const fetchUserGrowth = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/user_growth?range=${activeRange}`
      );
      if (data) {
        setUserGrowthData(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchTransactionsGrowth = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/transactions_growth?range=${activeRange}`
      );
      if (data) {
        setTransactionsGrowth(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchAppDownloads = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/app_downloads?range=${activeRange}`
      );
      if (data) {
        setAppDownloads(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchProducts = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/transactions_product?range=${activeRange}`
      );
      if (data) {
        setProducts(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchReferrals = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/referrals?range=${activeRange}`
      );
      if (data) {
        setReferrals(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchDiscardedUsers = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/discarded_users?range=${activeRange}`
      );
      if (data) {
        setDiscardedUsers(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  const fetchUnsubscribedEmails = async () => {
    try {
      const data = await getRequest(
        `/admin/performance_dashboard/unsubscribed_email?range=${activeRange}`
      );
      if (data) {
        setUnsubscribedEmails(data);
      }
    } catch (error) {
      console.error("Error fetching disputes:", error);
    }
  };

  useEffect(() => {
    fetchUserGrowth();
    fetchTransactionsGrowth();
    fetchProducts();
    fetchReferrals();
    fetchDiscardedUsers();
    fetchUnsubscribedEmails();
    fetchAppDownloads();
  }, [activeRange]);

  return (
    <div style={{ padding: "16px" }}>
      {/* Date Filter Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "flexStart",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <p>
          <b>Filter:&nbsp;</b>
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          {["ALL", "1D", "1W", "3W", "1M", "3M", "6M", "1Y"].map((range) => (
            <button
              key={range}
              style={{
                padding: "0 7px",
                background:
                  activeRange === range ||
                  (activeRange === "" && range === "ALL")
                    ? "#ff9800"
                    : "#4c3f84",
                color:
                  activeRange === range ||
                  (activeRange === "" && range === "ALL")
                    ? "#000"
                    : "#fff",
                border: "none",
                borderRadius: "4px",
                margin: "0 5px",
                cursor: "pointer",
              }}
              onClick={() => setRange(range === "ALL" ? "" : range)} // Map "ALL" to empty string
            >
              {range}
            </button>
          ))}
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
          color: "black",
        }}
      >
        {discardedUsers.data ||
        unsubscribedEmails.data ||
        userGrowthData.data ||
        referrals?.data ||
        appDownloads?.data ? (
          <SingleTotals
            discardedUsers={discardedUsers}
            unsubscribedEmails={unsubscribedEmails}
            userGrowthData={userGrowthData}
            referrals={referrals}
            appDownloads={appDownloads}
          />
        ) : (
          ""
        )}
        {products ? <Products data={products} /> : ""}
        {userGrowthData.data ? <UserGrowth data={userGrowthData} /> : ""}
        {transactionsGrowth.data ? (
          <TransactionsGrowth data={transactionsGrowth} />
        ) : (
          ""
        )}
      </div>
      <div>{referrals.data ? <Referrals data={referrals} /> : ""}</div>
    </div>
  );
};

export default PerformanceDashboard;
