import React from "react";
import { Field, FormikHelpers, FormikErrors } from "formik";
import { Option } from "react-select";
import SelectField from "../common/SelectField";
import { titleOptions, genderOptions, dialingCodeOptions } from "./helpers";
import { required } from "../../../../../utils/formValidations";
import { IconUserPurple } from "../../../../Dashboard/dashboardIcons";

interface Props {
  apiErrors?: any;
  errors: FormikErrors<{
    first_name: string;
    last_name: string;
    salutation: string;
    phone_code: string;
    phone_number: string;
    gender: string;
    occupation: string;
    name_on_card: string;
  }>;
  values: {
    first_name: string;
    last_name: string;
  };
  validateForm: () => Partial<FormikHelpers<any>>;
  handleNextStepChange: (
    validateForm: () => Partial<FormikHelpers<any>>,
    step: string
  ) => void;
  setFieldValue: (field: string, value: any) => void;
  handlePreviousStepChange: (step: string) => void;
}

const PersonalDetails: React.FC<Props> = ({
  apiErrors,
  errors,
  values,
  validateForm,
  setFieldValue,
  handleNextStepChange,
  handlePreviousStepChange,
}) => {
  return (
    <>
      <div className="card-body">
        <div id="personal-details" className="request-details-cont">
          <div className="request-left-col">
            <i className="fas fa-user-circle card-mail-icon"></i>
            <br/>
            <h4 className="card-reqform-title font_3">
              Personal Details
            </h4>
          </div>
          <div className="row card-deck mb-5 request-right-col">
            <form className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="salutation" className="card-sub-title">Salutation</label>
                  <Field
                    name="salutation"
                    placeholder="Select salutation"
                    component={SelectField}
                    validate={required}
                    options={titleOptions}
                    error={errors.salutation}
                  />
                  {errors.salutation && !apiErrors?.salutation_error && (
                    <small className="error">{errors.salutation}</small>
                  )}
                  {apiErrors?.salutation_error && (
                    <small className="error text-danger">
                      {apiErrors.salutation_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="first_name" className="card-sub-title">First Name</label>
                  <Field
                    name="first_name"
                    className="form-control"
                    validate={required}
                    error={errors.first_name}
                    onChange={(e) => {
                      setFieldValue("first_name", e.target.value);
                      setFieldValue(
                        "name_on_card",
                        `${e.target.value} ${values.last_name}`
                      );
                    }}
                  />
                  {errors.first_name && !apiErrors?.first_name_error && (
                    <small className="error">{errors.first_name}</small>
                  )}
                  {apiErrors?.first_name_error && (
                    <small className="error text-danger">
                      {apiErrors.first_name_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="middle_name" className="card-sub-title">Middle Name</label>
                  <Field name="middle_name" className="form-control" />
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="last_name" className="card-sub-title">Last Name</label>
                  <Field
                    name="last_name"
                    className="form-control"
                    validate={required}
                    onChange={(e) => {
                      setFieldValue("last_name", e.target.value);
                      setFieldValue(
                        "name_on_card",
                        `${values.first_name} ${e.target.value}`
                      );
                    }}
                  />
                  {errors.last_name && !apiErrors?.last_name_error && (
                    <small className="error">{errors.last_name}</small>
                  )}
                  {apiErrors?.last_name_error && (
                    <small className="error text-danger">
                      {apiErrors.last_name_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="alert alert-warning" role="alert">
                <i
                  className="fa fa-info-circle"
                  style={{ verticalAlign: "middle" }}
                />{" "}
                <span>Enter your name as it appears on your passport</span>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="phone_number" className="card-sub-title">Phone Number</label>
                  <div className="d-flex">
                    <div
                      style={{
                        width: "50%",
                        marginRight: "7px",
                        fontSize: "14px",
                      }}
                    >
                      <Field
                        name="phone_code"
                        component={SelectField}
                        validate={required}
                        options={dialingCodeOptions}
                        placeholder="Code"
                        error={errors.phone_code}
                      />
                    </div>
                    <Field
                      name="phone_number"
                      type="number"
                      placeholder="Enter Phone Number"
                      className={`form-control ${
                        errors.phone_number ? "is-invalid" : ""
                      }`}
                      validate={required}
                    />
                  </div>
                  {errors.phone_number && !apiErrors?.phone_number_error && (
                    <small className="error">{errors.phone_number}</small>
                  )}
                  {apiErrors?.phone_number_error && (
                    <small className="error text-danger">
                      {apiErrors.phone_number_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="gender" className="card-sub-title">Gender</label>
                  <Field
                    name="gender"
                    placeholder="Select gender"
                    component={SelectField}
                    validate={required}
                    options={genderOptions}
                    error={errors.gender}
                  />
                  {errors.gender && !apiErrors?.gender_error && (
                    <small className="error">{errors.gender}</small>
                  )}
                  {apiErrors?.gender_error && (
                    <small className="error text-danger">
                      {apiErrors.gender_error}
                    </small>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 form-group">
                  <label htmlFor="occupation" className="card-sub-title">Occcupation</label>
                  <Field
                    name="occupation"
                    className="form-control"
                    placeholder="Enter your occupation"
                    validate={required}
                  />
                  {errors.occupation && !apiErrors?.occupation && (
                    <small className="error">{errors.occupation}</small>
                  )}
                  {apiErrors?.occupation_error && (
                    <small className="error text-danger">
                      {apiErrors.occupation_error}
                    </small>
                  )}
                </div>
                <div className="col-md-6 form-group">
                  <label htmlFor="name_on_card" className="card-sub-title">Name On Card</label>
                  <Field
                    name="name_on_card"
                    className="form-control"
                    placeholder="Enter name on card"
                    validate={required}
                  />
                  {errors.name_on_card && !apiErrors?.name_on_card && (
                    <small className="error">{errors.name_on_card}</small>
                  )}
                  {apiErrors?.name_on_card && (
                    <small className="error text-danger">
                      {apiErrors.name_on_card_error}
                    </small>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="p-3">
        <div className="d-flex flex-row justify-content-between">
          <button
            type="button"
            className="btn btn-outline-purple float-left"
            onClick={(e) => handlePreviousStepChange("cardType")}
          >
            <i className="fa fa-arrow-left"></i> Previous
          </button>
          <button
            type="button"
            className="btn btn-purple float-right"
            onClick={(e) =>
              handleNextStepChange(validateForm, "identification")
            }
          >
            Next <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default PersonalDetails;
