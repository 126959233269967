export const filtersMapping = {
  pending: "Pending",
  completed: "Completed",
  unconfirmed: "Unconfirmed",
  needs_confirmation: "Needs confirmation"
};

export const typesMapping = {
  "sent transfer": "Sent Transfer",
  "funding deposit": "Funding Deposit",
  "payout withdrawal": "Payout Withdrawal",
  "convert deposit": "Convert Deposit",
  "received transfer": "Received Transfer",
  "migration transfer": "Migration Transfer",
  "sent withdrawal": "Sent Withdrawal",
  transaction: "Store Widget Transaction"
};
