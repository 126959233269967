import React from "react";
import { IconUserPurple, IconUserIdentification, IconLocationPurple, IconPreviewPurple, IconCheckPurple } from "../../../../Dashboard/dashboardIcons"
import { canNavigate, isCompleted } from "./helpers";


const handleNavigate = (currentStep, step, handleSetStep) => {
  if(canNavigate(currentStep, step)){
    handleSetStep(step)
  }
}
const Header = ({ step = "personal", handleSetStep }) => (
  <div className="progress-wrapper d-flex flex-column flex-md-row justify-content-between mt-3 cardreq-header">
    <div onClick={() => handleNavigate(step, "personal", handleSetStep)} className={`progress-item d-flex flex-row justify-content-between align-items-center opacity-25 ${step === "personal" ? "cardreq-active-step" : ""} ${canNavigate(step, "personal") ? "can-navigate" : ""}`}>
      <div className={`d-flex flex-row align-items-center justify-content-start`}>
        <IconUserPurple />
        <div className="progress-text d-flex flex-column pl-2">
          <span className="step-number text-muted font-weight-bold font-0_8">Step 1/4</span>
          <span className="font-0_9">Personal Details</span>
        </div>
      </div>
      {isCompleted(step, "personal") && <IconCheckPurple />}
    </div>
    
    <div onClick={() => handleNavigate(step, "identification", handleSetStep)} className={`progress-item d-flex flex-row justify-content-between align-items-center opacity-25 ${step === "identification" ? "cardreq-active-step" : ""} ${canNavigate(step, "identification") ? "can-navigate" : ""}`}>
      <div className={`d-flex flex-row align-items-center justify-content-start`}>`
        <IconUserIdentification />
        <div className="progress-text d-flex flex-column pl-2">
          <span className="step-number text-muted font-weight-bold font-0_8">Step 2/4</span>
          <span className="font-0_9">Means of Identification</span>
        </div>
      </div>
      {isCompleted(step, "identification") && <IconCheckPurple />}
    </div>
    <div onClick={() => handleNavigate(step, "mailing", handleSetStep)} className={`progress-item d-flex flex-row align-items-center justify-content-between opacity-25 ${step === "mailing" ? "cardreq-active-step" : ""} ${canNavigate(step, "mailing") ? "can-navigate" : ""}`}>
      <div className={`d-flex flex-row align-items-center justify-content-start`}>
        <IconLocationPurple />
        <div className="progress-text d-flex flex-column pl-2">
          <span className="step-number text-muted font-weight-bold font-0_8">Step 3/4</span>
          <span className="font-0_9">Card Mailing Address</span>
        </div>
      </div>
      {isCompleted(step, "mailing") && <IconCheckPurple />}
    </div>
    <div onClick={() => handleNavigate(step, "preview", handleSetStep)} className={`progress-item d-flex flex-row align-items-center justify-content-start opacity-25 ${step === "preview" ? "cardreq-active-step" : ""} ${canNavigate(step, "preview") ? "can-navigate" : ""}`}>
      <IconPreviewPurple />
      <div className="progress-text d-flex flex-column pl-2">
        <span className="step-number text-muted font-weight-bold font-0_8">Step 4/4</span>
        <span className="font-0_9">Preview</span>
      </div>
    </div>

  </div>
  
);

export default Header;
