import React, { useState } from "react";
import { CardType } from "../../Types";
import CouponModal from "../CouponModal";
import plasticBlack from "../../../../../../../assets/images/plastic_black.png";
import metalSilver from "../../../../../../../assets/images/metal_silver.png";
import metalGold from "../../../../../../../assets/images/metal_gold.png";
import metalBlack from "../../../../../../../assets/images/metal_black.png";

interface Props {
  cardType: CardType;
  onClickCardType: (e: any, cardType: CardType) => void;
  handleTooltipClick: (title: string) => void;
  updateCouponCode: (couponCode: string) => void;
}
const CardDetail: React.FC<Props> = ({
  cardType,
  onClickCardType,
  handleTooltipClick,
  updateCouponCode,
}) => {
  const [redeemCoupon, setRedeemCoupon] = useState<boolean>(false);
  const [discountedFee, setDiscountedFee] = useState(null);
  const [claimable, setClaimable] = useState(false);

  const onClickRedeemCoupon = (e: any) => {
    e.preventDefault();

    setRedeemCoupon(true);
  };

  const cardTypeImages: { [key: string]: string } = {
    Plastic: `${plasticBlack}`,
    "Metal Silver": `${metalSilver}`,
    "Metal Gold": `${metalGold}`,
    "Metal Black": `${metalBlack}`,
  };

  const honorCouponRedeemSuccess = (response: any) => {
    cardType.discounted_fee = response.discounted_fee;
    cardType.coupon_code = response.coupon_code;
    cardType.is_coupon_claimable = true;
    setDiscountedFee(response.discounted_fee);
    setClaimable(true);
    setRedeemCoupon(false);
    updateCouponCode(response.coupon_code);
  };

  return (
    <>
      <div className="card-main-details text-center">
        <img
          src={cardTypeImages[cardType.name]}
          alt="BC Debit Card"
          className="card-img"
        />
        <div className="card-name mt-2">{cardType.name}</div>
        <div className="card-fee">
          {claimable ? (
            <>${discountedFee}</>
          ) : (
            <>${cardType.application_fee} </>
          )}
          <i
            className="fa fa-info-circle card-fee-info"
            aria-hidden="true"
            title="The total cost of card application, processing, and shipping to any location worldwide."
            onClick={(e) =>
              handleTooltipClick(e.currentTarget.getAttribute("title") || "")
            }
          ></i>
          {cardType.coupon_code && (
            <p style={{ fontSize: "14px", margin: "0" }}>
              Coupon: {cardType.coupon_code}
            </p>
          )}
        </div>
      </div>

      <div className="card-other-details">
        <button
          className="select-button mb-3"
          onClick={(e) => onClickCardType(e, cardType)}
        >
          Get Started <i className="fa fa-long-arrow-right"></i>
        </button>
        <div>
          <a
            href="#"
            className="cards-coupon"
            onClick={(e) => onClickRedeemCoupon(e)}
          >
            <i className="fas fa-angle-right"></i>
            {" "}
            Redeem Coupon
            {" "}
            <i className="fas fa-tags"></i>
          </a>
        </div>
        <br />
        <ul>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Account load fee is incurred in the respective currency for each load done to the prefunding account of the client card."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              Loading Fee:{" "}
              <span className="card-details-data">{cardType.loading_fee}%</span>
            </span>
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Conversion of crypto to fiat rates."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              Conversion Fee:{" "}
              <span className="card-details-data">
                {cardType.conversion_fee}%
              </span>
            </span>
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Fee charged to replace card."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              {" "}
              Lost Card Replacement Fee:{" "}
              <span className="card-details-data">
                ${cardType.replacement_fee}
              </span>
            </span>
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Fee charged annually for card maintenance."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              {" "}
              Annual Maintenance Fee:{" "}
              <span className="card-details-data">
                ${cardType.yearly_maintenance_fee}
              </span>
            </span>
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Fee charged by ATMs upon withdrawal."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              ATM Withdrawal Fee:{" "}
              <span className="card-details-data">
                {cardType.atm_withdrawal_fee}%
              </span>
            </span>
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Fee charged by ATMs to check balance."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              ATM Check Balance Fee:{" "}
              <span className="card-details-data">
                {cardType.check_balance_fee_atm}
              </span>
            </span>
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Fee charged for use of cards at with a POS as well as various online platforms."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              {" "}
              Pos & Online Fee:{" "}
              <span className="card-details-data">
                {cardType.pos_online_fee}%
              </span>
            </span>{" "}
          </li>
          <li>
            <i
              className="fa fa-info-circle cards-info-icons"
              aria-hidden="true"
              title="Maximum amount card can be credited monthly."
              onClick={(e) =>
                handleTooltipClick(e.currentTarget.getAttribute("title") || "")
              }
            ></i>
            <span>
              {" "}
              <strong>Monthly Spend Cap: </strong>
              <span className="card-details-data">
                {cardType.loading_cap_monthly}
              </span>
            </span>{" "}
          </li>
        </ul>
        <CouponModal
          cardType={cardType.type}
          showCouponModal={redeemCoupon}
          handleShowCouponModal={setRedeemCoupon}
          honorCouponRedeemSuccess={honorCouponRedeemSuccess}
        />
      </div>
    </>
  );
};

export default CardDetail;
